import React from 'react';
import CustomTabs from 'components/CustomTabs/CustomTabs';
import FilePreviewModal from 'components/FilePreviewModal/FilePreviewModal';
import Loading from 'components/Loading/Loading';
import Table from 'components/Table/Table.js';
import { useFailedLetters } from './FailedLetters.hook';

export default function FailedLetters() {
  const {
    lettersData,
    isLoading,
    isPreviewModalOpen,
    selectedLetterName,
    fileUrl,
    setIsPreviewModalOpen,
    currentTab,
    onTabChange,
  } = useFailedLetters();

  const tabs = [
    {
      tabName: 'Not Resolved',
      tabContent: (
        <Loading loading={isLoading} style={{ minHeight: '60vh' }}>
          <Table
            tableHeaderColor="primary"
            tableHead={[
              'Letter ID',
              'Client ID',
              'Client Name',
              'Admin Name',
              'File Name',
              'File Type',
              'Status Details',
              'Invoice ID',
              'Invoice Date',
              'Acceptance Date',
              'Processed Date',
              'Actions',
            ]}
            tableData={lettersData}
            pagination
          />
        </Loading>
      ),
    },
    {
      tabName: 'Resolved',
      tabContent: (
        <Loading loading={isLoading} style={{ minHeight: '60vh' }}>
          <Table
            tableHeaderColor="primary"
            tableHead={[
              'Letter ID',
              'Client ID',
              'Client Name',
              'File Name',
              'File Type',
              'Status Details',
              'Invoice ID',
              'Invoice Date',
              'Acceptance Date',
              'Processed Date',
              'Actions',
            ]}
            tableData={lettersData}
            pagination
          />
        </Loading>
      ),
    },
  ];

  return (
    <>
      <CustomTabs
        value={currentTab}
        changeValue={onTabChange}
        title="Failed Letters"
        headerColor="warning"
        tabs={tabs}
      />
      {fileUrl && (
        <FilePreviewModal
          fileUrl={fileUrl}
          fileName={selectedLetterName}
          isOpen={isPreviewModalOpen}
          onClose={() => setIsPreviewModalOpen(false)}
        />
      )}
    </>
  );
}
