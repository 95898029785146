import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardList from 'components/CardList/CardList';
import Button from 'components/CustomButtons/Button.js';
import LettersTable from 'components/LettersTable/LettersTable';
import FailedLetters from './components/FailedLetters/FailedLetters';
import LetterPreview from './components/LetterPreview/LetterPreview';
import UploadDocumentModal from './components/UploadDocumentModal/UploadDocumentModal';
import { useLetters } from './Letters.hook';
import styles from './Letters.style';
const useStyles = makeStyles(styles);

export default function Letters({ client }) {
  const classes = useStyles();
  const {
    onSubmit,
    isUploadLoading,
    isUploadDocumentModalOpen,
    setIsUploadDocumentModalOpen,
    form,
    csvLetters,
    setCsvLetters,
    index,
    backendFileNameObj,
    setIndex,
    onDeclineLetter,
    onSendLetter,
    isSendLetterLoading,
    letters,
    isGetLoading,
    paginate,
    totalPosts,
    currentPage,
  } = useLetters({ client });

  return (
    <>
      <Card>
        <CardBody>
          <div className={classes.root}>
            {csvLetters?.length > 0 ? (
              <CardList
                noDataText={'noLetters'}
                list={csvLetters}
                setList={setCsvLetters}
                index={index}
                setIndex={setIndex}
                withNavigation={false}
              >
                <LetterPreview
                  onDeclineLetter={onDeclineLetter}
                  onSendLetter={onSendLetter}
                  backendFileNameObj={backendFileNameObj}
                  letterData={csvLetters[index]}
                  isSendLetterLoading={isSendLetterLoading}
                />
              </CardList>
            ) : (
              <div className={classes.sendContainer}>
                <Button
                  onClick={() => {
                    setIsUploadDocumentModalOpen(true);
                  }}
                  className={classes.uploadButton}
                  color="primary"
                  loading={isUploadLoading}
                  send
                >
                  Send Letters
                </Button>
              </div>
            )}

            <LettersTable
              letters={letters}
              isGetLoading={isGetLoading}
              paginate={paginate}
              totalPosts={totalPosts}
              currentPage={currentPage}
            />
            <UploadDocumentModal
              isOpen={isUploadDocumentModalOpen}
              onClose={() => setIsUploadDocumentModalOpen(false)}
              form={form}
              onSubmit={onSubmit}
              isLoading={isUploadLoading}
            />
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <FailedLetters />
        </CardBody>
      </Card>
    </>
  );
}

Letters.propTypes = { client: PropTypes.object.isRequired };
