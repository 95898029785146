import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';
import { useHttpClient } from 'shared/hooks/http-hook';

export const useLetterPreview = ({ letterData, backendFileNameObj }) => {
  const [sendRequest, isLoading, sendRequestError] = useHttpClient();
  const [fileUrl, setFileUrl] = useState();

  useEffect(() => {
    if (sendRequestError?.message) {
      toast.error('Error Occur');
    }
  }, [sendRequestError]);

  useEffect(() => {
    if (backendFileNameObj.filename && letterData) {
      fetchLetterDocument(letterData, backendFileNameObj);
    }
  }, [letterData, backendFileNameObj]);

  const fetchLetterDocument = useCallback(
    async (letterData, backendFileNameObj) => {
      const response = await sendRequest(api.letterResource.getLetterDocument({ letterData, ...backendFileNameObj }));
      const byteCharacters = window.atob(response);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      // convert blob to file
      const file = new File([blob], 'contract.pdf', {
        type: 'application/pdf',
      });
      const objectUrl = URL.createObjectURL(file);
      setFileUrl(objectUrl);
    },
    [letterData, backendFileNameObj],
  );

  return {
    isLoading,
    fileUrl,
  };
};
