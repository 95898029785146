import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import AutocompleteInput from 'components/AutocompleteInput/AutocompleteInput';
import CustomInput from 'components/CustomInput/CustomInput';
import RichTextInput from 'components/CustomInput/RichTextInput';
import CustomSwitch from 'components/CustomSwitch/CustomSwitch';
import ImageUpload from 'components/CustomUpload/ImageUpload';
import DropFiles from 'components/DropFiles/DropFiles';
import SelectInput from 'components/SelectInput/SelectInput';

const GenericInput = props => {
  const { type, name, label, options, error, value, onChange, tooltip, tooltipIconClasses, dropFileProps = {} } = props;
  let { dependency, getShowCondition, formState, setValue, defaultValue } = options;
  if (!formState) {
    formState = {};
  }
  const [data, setData] = useState([]);
  const [isShown, setIsShown] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (getShowCondition && getShowCondition(formState[dependency])) {
      setIsShown(true);
      if (value === undefined) setValue(name, defaultValue);
    } else if (getShowCondition && !getShowCondition(formState[dependency])) {
      setIsShown(false);
      if (value !== undefined) setValue(name, undefined);
    }
  }, [formState[dependency]]);

  const handleData = useCallback(
    choices => {
      if (type === 'select' && options.addAllChoice) {
        setData([{ name: t('all'), value: null }, ...choices]);
      } else {
        setData(choices);
      }
    },
    [type, options, options?.addAllChoice, setData],
  );

  useEffect(() => {
    if (!data.length) {
      let temp = [];
      const compare = (a, b) => {
        const temp1 = typeof a[options.sortBy] === 'string' ? a[options.sortBy].toUpperCase() : a[options.sortBy];

        const temp2 = typeof b[options.sortBy] === 'string' ? b[options.sortBy].toUpperCase() : b[options.sortBy];

        if (temp1 < temp2) {
          return -1;
        }
        if (temp1 > temp2) {
          return 1;
        }
        return 0;
      };
      if (options?.data) {
        temp = [...options.data];
        if (options?.sortBy !== undefined) {
          handleData(temp.sort(compare));
        } else {
          handleData(temp);
        }
      } else if (options?.getData) {
        (async () => {
          try {
            const resp = (await options.getData()).data;
            if (options.rawDataConvertor) {
              temp = [...options.rawDataConvertor(resp.data)];
            } else {
              temp = [...resp.data];
            }
            if (options?.sortBy !== undefined) {
              handleData(temp.sort(compare));
            } else {
              handleData(temp);
            }
          } catch (err) {
            toast.error(`${t('errorWhileLoading')} ${label}`);
          }
        })();
      }
    }
  }, [options]);
  const inputField =
    type === 'text' ||
    type === 'number' ||
    type === 'date' ||
    type === 'datetime-local' ||
    type === 'time' ||
    type === 'month' ? (
      <CustomInput
        formControlProps={{ fullWidth: true }}
        inputProps={{
          onChange,
          value:
            type === 'date' ? value?.split('T')[0] : type === 'month' ? value?.split('T')[0]?.substring(0, 7) : value,
          type,
          multiline: options?.multiline,
          rows: options?.multiline ? (options.rows ? options.rows : 5) : 1,
          max: options?.max,
          min: options?.min,
          shrink: options?.shrink,
        }}
        labelText={label}
        error={!!error}
        helperText={error}
        name={name}
        id={name}
      />
    ) : type === 'select' ? (
      <SelectInput
        id={name}
        labelText={label}
        placeholder={label}
        error={!!error}
        errorMessage={error}
        data={data}
        selectedValue={value}
        onSelect={onChange}
        shrink={options?.shrink}
      />
    ) : type === 'autocomplete' ? (
      <AutocompleteInput
        getOptionDisabled={
          options.multiple
            ? option => {
                if (options.maxSelections && value?.length === options.maxSelections) return true;
                else {
                  return value?.some(selectedEl => {
                    if (typeof option === 'object') return selectedEl.id === option.value;
                    else return selectedEl === option;
                  });
                }
              }
            : undefined
        }
        formControlProps={{ fullWidth: true }}
        options={data}
        id={name}
        labelText={label}
        selectedValue={value}
        onChange={onChange}
        error={!!error}
        helperText={error}
        {...options}
      />
    ) : type === 'switch' ? (
      <CustomSwitch
        value={value}
        onChange={onChange}
        labelText={label}
        disabled={options.disabled}
        tooltip={tooltip}
        tooltipIconClasses={tooltipIconClasses}
        error={!!error}
        helperText={error}
        {...(name && { name })}
      />
    ) : type === 'rich-text' ? (
      <RichTextInput
        value={value}
        setValue={options.setRteValue}
        labelText={label}
        onChange={onChange}
        dir={options.rteDir}
        {...options}
      />
    ) : type === 'image' ? (
      <ImageUpload
        label={label}
        file={value}
        setFile={onChange}
        addButtonProps={{
          color: 'primary',
          round: true,
        }}
        changeButtonProps={{
          color: 'primary',
          round: true,
        }}
        removeButtonProps={{
          color: 'danger',
          round: true,
        }}
      />
    ) : type === 'dropzone' ? (
      <DropFiles
        files={value ? value : []}
        setFiles={onChange}
        maxFiles={options?.maxFiles}
        dropFileProps={dropFileProps}
      />
    ) : null;

  return getShowCondition === undefined || isShown ? inputField : null;
};

GenericInput.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.object,
  error: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  tooltipIconClasses: PropTypes.string,
  dropFileProps: PropTypes.object,
};

export default GenericInput;
