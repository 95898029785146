const filePreviewModalStyle = theme => ({
  buttonContainer: {
    display: 'flex',
    width: '100%',
  },
  button: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      margin: 'auto',
    },
  },
});

export default filePreviewModalStyle;
