import React, { lazy, Suspense } from 'react';
import BugReportIcon from '@material-ui/icons/BugReport';
import enums from 'enums';
const resource = enums.Resources.BUGS;

const Bugs = lazy(() => import('views/Bugs/Bugs'));
const ViewBugs = lazy(() => import('views/Bugs/ViewBugs/ViewBugs'));

export default {
  path: '/bugs',
  name: 'bugs',
  icon: BugReportIcon,
  render: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <Bugs />
    </Suspense>
  ),
  layout: '/admin',
  randomKey: true,
  children: true,
  resource,
  views: [
    {
      path: '/bugs/:id',
      name: 'viewBugs',
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          <ViewBugs />
        </Suspense>
      ),
      layout: '/admin',
      randomKey: true,
      resource,
    },
  ],
};
