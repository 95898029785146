import 'assets/scss/material-dashboard-pro-react.scss?v=1.9.0';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import './i18n';

// TODO:
// 1. push source map to sentry
// 2. check network bug: sentry user bug ..

const sentryReplay = new Sentry.Replay({
  maskAllText: false,
  maskAllInputs: false,
  blockAllMedia: false,
  networkDetailAllowUrls: [/\S*/],
  networkRequestBody: true,
});

if (process.env.REACT_APP_NODE_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: `https://${process.env.REACT_APP_SENTRY_DSN}@sentry.juhi.de/2`,
    replaysSessionSampleRate: 1.0,
    integrations: [sentryReplay],
  });
}
ReactDOM.render(<App />, document.getElementById('root'));
