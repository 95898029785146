import { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import { useHttpClient } from './http-hook';

export const useAuth = () => {
  const [userData, setUserData] = useState(false);

  const [sendRequest] = useHttpClient();

  const login = useCallback(response => {
    setUserData({
      email: response.email,
      name: response.name,
      id: response.id,
      adminType: response.adminType,
    });
    api.setAuthToken(response.accessToken);
  }, []);

  const logout = useCallback(async () => {
    setUserData(null);
    await api.clearAuthToken();
    await api.logout();
  }, []);

  const updateUserData = useCallback(async (currentUserData, updatedUserData) => {
    const newUserData = { ...currentUserData, ...updatedUserData };
    setUserData(newUserData);
  }, []);

  useEffect(() => {
    const authAutoSignIn = async () => {
      try {
        let response = await sendRequest(api.refreshToken());
        login(response);
      } catch (err) {
        logout();
      }
    };
    authAutoSignIn();
  }, [login]);

  return { userData, login, logout, updateUserData };
};
