import React from 'react';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CustomMaterialTable from 'components/CustomMaterialTable/CustomMaterialTable';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Loading from 'components/Loading/Loading';
import { useClientsBudgetList } from './ClientsBudgetList.hook';

function ClientsBudgetList() {
  const { isLoading, title, columnsConfig, tableDate, classes, getCsvData } = useClientsBudgetList();
  return (
    <div>
      <div className={classes.topContainer}>{title}</div>

      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Breadcrumbs />
          <Card mobileTransparent>
            <CardBody>
              <Loading loading={isLoading} style={{ height: '60vh' }}>
                <CustomMaterialTable
                  options={{
                    toolbar: true,
                    columnsButton: true,
                    search: true,
                    searchFieldAlignment: 'left',
                    exportAllData: true,
                    exportButton: {
                      csv: true,
                      pdf: false,
                    },
                  }}
                  getProcessedCsvData={getCsvData}
                  csvFileName={title}
                  tableData={tableDate}
                  columnsConfig={columnsConfig}
                />
              </Loading>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default ClientsBudgetList;
