import { dangerColor, whiteColor } from 'assets/jss/material-dashboard-pro-react';

export default () => ({
  uploadIcon: {
    color: '#DEDEDE',
    background: whiteColor,
    height: 100,
    width: 100,
  },
  deleteIcon: { color: dangerColor[0], cursor: 'pointer' },
});
