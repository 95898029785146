import React from 'react';
import Button from '@material-ui/core/Button';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import styles from 'assets/jss/material-dashboard-pro-react/components/paginationStyle.js';

const useStyles = makeStyles(styles);

export default function Pagination(props) {
  const { color, className, totalPosts, postsPerPage, currentPage, paginate } = props;
  const classes = useStyles();
  const paginationClasses = classNames(classes.pagination, className);

  const pages = [];
  const totalPages = Math.ceil(totalPosts / postsPerPage);
  pages.push({ text: 'PREV' });

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push({ text: i, active: i === currentPage ? true : false });
    }
  } else {
    let difference = totalPages - currentPage;
    if (difference < 2) {
      difference += 1;
      for (let i = currentPage - 5 + difference; i <= totalPages; i++) {
        pages.push({ text: i, active: i === currentPage ? true : false });
      }
    } else if (difference >= totalPages - 2) {
      for (let i = 1; i <= 5; i++) {
        pages.push({ text: i, active: i === currentPage ? true : false });
      }
    } else {
      for (let i = currentPage - 2; i <= currentPage + 2; i++) {
        pages.push({ text: i, active: i === currentPage ? true : false });
      }
    }
  }
  pages.push({ text: 'NEXT' });

  return (
    <ul className={paginationClasses}>
      {pages.map((prop, key) => {
        const paginationLink = classNames({
          [classes.paginationLink]: true,
          [classes[color]]: prop.active,
          [classes.disabled]: prop.disabled,
        });
        return (
          <li className={classes.paginationItem} key={key}>
            {prop.text === 'NEXT' ? (
              <Button
                onClick={() => paginate(totalPages !== currentPage ? currentPage + 1 : currentPage)}
                className={paginationLink}
                disabled={prop.disabled}
              >
                {prop.text}
              </Button>
            ) : prop.text === 'PREV' ? (
              <Button
                onClick={() => paginate(1 !== currentPage ? currentPage - 1 : currentPage)}
                className={paginationLink}
                disabled={prop.disabled}
              >
                {prop.text}
              </Button>
            ) : (
              <Button onClick={() => paginate(prop.text)} className={paginationLink} disabled={prop.disabled}>
                {prop.text.toString()}
              </Button>
            )}
          </li>
        );
      })}
    </ul>
  );
}

Pagination.defaultProps = {
  color: 'primary',
};

Pagination.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  className: PropTypes.string,
  totalPosts: PropTypes.number,
  postsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  paginate: PropTypes.func,
};
