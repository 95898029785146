import * as yup from 'yup';
import api from 'services/api';

export default [
  {
    column: 1,
    fields: [
      {
        name: 'name',
        type: 'text',
        label: 'service',
        validation: yup.string().required('Required'),
      },
      {
        name: 'serviceCategoryId',
        type: 'select',
        label: 'serviceCategory',
        options: {
          getData: api.serviceCategoriesResource.getAll,
          rawDataConvertor: data =>
            data?.map(entry => ({
              value: entry.id,
              name: entry.name,
            })),
        },
        validation: yup.mixed().required('Required'),
      },
    ],
  },
];
