import React from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Letters from 'views/Letters/Letters';
import enums from 'enums';

const resource = enums.Resources.HELPER_TASKS;

export default {
  path: '/letters',
  name: 'Letters',
  icon: AssignmentIcon,
  render: () => <Letters />,
  layout: '/admin',
  randomKey: true,
  resource,
};
