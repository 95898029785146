import * as yup from 'yup';

export default [
  {
    column: 1,
    fields: [
      {
        name: 'name',
        type: 'text',
        label: 'accommodationSituation',
        validation: yup.string().required('Required'),
      },
    ],
  },
];
