const imageUploadStyle = {
  root: {
    minHeight: 30,
    width: '100%',
    paddingTop: 27,
    marginBottom: 14,
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: '#AAAAAA',
    fontSize: 14,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    margin: 0,
    marginBottom: 10,
  },
};

export default imageUploadStyle;
