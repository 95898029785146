import React, { lazy, Suspense } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import enums from 'enums';

const Emails = lazy(() => import('views/Emails/Emails'));
const resource = enums.Resources.HELPER_TASKS;

export default {
  path: '/emails',
  name: 'Emails',
  icon: EmailIcon,
  render: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <Emails />
    </Suspense>
  ),
  layout: '/admin',
  randomKey: true,
  resource,
};
