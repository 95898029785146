import React, { lazy, Suspense } from 'react';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import enums from 'enums';

const HelperTasksPage = lazy(() => import('views/HelperTasks/pages/HelperTasksPage/HelperTasksPage'));
const resource = enums.Resources.HELPER_TASKS;

export default {
  path: '/helperTasks',
  name: 'Helper Tasks',
  icon: AssignmentTurnedInIcon,
  render: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <HelperTasksPage />
    </Suspense>
  ),
  layout: '/admin',
  randomKey: true,
  resource,
};
