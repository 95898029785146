import { getDropdownValuesCrud } from 'routes/dropdownValuesCrud';
import insuranceCompaniesFormStructure from 'views/DropdownValues/Forms/insuranceCompaniesFormStructure';
import api from 'services/api';

const tableHead = ['id', 'name', 'ikNumber', 'nursingInsuranceNumber'];

const rawDataConvertor = listData =>
  listData?.map(entry => {
    return [entry.id, entry.name, entry.healthIkNumber, entry.nursingIkNumber];
  });

export default getDropdownValuesCrud({
  name: 'insuranceCompany',
  pluralName: 'insuranceCompanies',
  formStructure: insuranceCompaniesFormStructure,
  dataResource: api.insuranceCompaniesResource,
  customRawDataConvertor: rawDataConvertor,
  tableHead: tableHead,
});
