import React, { lazy, Suspense } from 'react';
import MapIcon from '@material-ui/icons/Map';
import enums from 'enums';

const MapView = lazy(() => import('views/MapView/MapView'));
const resource = enums.Resources.MAP;

export default {
  path: '/map',
  name: 'Map',
  icon: MapIcon,
  render: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <MapView />
    </Suspense>
  ),
  layout: '/admin',
  randomKey: true,
  resource,
};
