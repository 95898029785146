import { dangerColor } from 'assets/jss/material-dashboard-pro-react';
import { hexToRgb } from 'assets/jss/material-dashboard-pro-react';

const fileCardStyle = () => ({
  root: {
    width: '100%',
    display: 'flex',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: 'white',
    height: 60,
    margin: '10px 0px',
    padding: '6px',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
    maxHeight: 50,
    width: 30,
    objectFit: 'contain',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
  },
  size: {
    fontSize: 13,
    fontWeight: 200,
    margin: 0,
  },
  previewIcon: {
    color: 'white',
    backgroundColor: 'rgba(' + hexToRgb('#3f51b5') + ', 0.26)',
    borderRadius: '50%',
    transition: 'all 0.3s',
    marginLeft: 'auto',
    marginRight: 10,
    padding: 5,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#3f51b5',
    },
  },
  previewIconNotSupported: {
    color: 'white',
    backgroundColor: 'rgba(' + hexToRgb('#3f51b5') + ', 0.26)',
    borderRadius: '50%',
    marginLeft: 'auto',
    marginRight: 10,
    padding: 5,
  },
  deleteIcon: {
    color: 'rgba(' + hexToRgb(dangerColor[0]) + ', 0.26)',
    cursor: 'pointer',
    transition: 'all 0.3s',
    marginRight: 10,
    height: 28,
    width: 28,
    '&:hover': {
      color: dangerColor[0],
    },
  },
});

export default fileCardStyle;
