const letterModalStyle = theme => ({
  buttonContainer: {
    display: 'flex',
    width: '100%',
  },
  button: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      margin: 'auto',
    },
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 15,
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 25,
  },
  note: {
    color: 'red',
  },
});

export default letterModalStyle;
