import React from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import CustomLink from 'components/CustomLink/CustomLink';
import { getDateString } from 'utils';
import enums from 'enums/index';
import { Icon } from '../../../node_modules/@material-ui/core/index';

export const useCustomMaterialTable = ({
  csvFileName = 'exported_data',
  classes,
  materialTableClasses,
  getProcessedCsvData,
}) => {
  const { t, i18n } = useTranslation();

  const createColumnsArray = columnsConfig => {
    const columns = columnsConfig.map(config => createColumn(config));
    return columns;
  };

  const createColumn = config => {
    config = modifyOnType(config);
    let column = {
      title: t(config.field),
      cellStyle: materialTableClasses.cellStyle,
      ...(config.component && {
        render: (rowData, type) => {
          if (type === 'row') {
            return config.component(rowData[config.field]);
          } else {
            return config.component(rowData);
          }
        },
      }),
      ...(config.sort && {
        customSort: (a, b, type) => {
          if (type === 'row') {
            return config.sort(a[config.field], b[config.field]);
          } else {
            return config.sort(a, b);
          }
        },
      }),

      ...config, //this stay at its position
    };
    return column;
  };

  const modifyOnType = config => {
    let modifiedConfig = { ...config };
    switch (config.componentType) {
      case 'date':
        modifiedConfig = {
          ...modifiedConfig,
          component: data => (
            <div className={classes.dateText}>
              {data ? getDateString(data, i18n.language, !config.withoutTime) : '-'}
            </div>
          ),
          sort: (a, b) => new Date(a) - new Date(b),
        };
        break;
      case 'nameLink':
        modifiedConfig = {
          ...modifiedConfig,
          component: data =>
            data ? (
              <CustomLink href={`/admin/${config.field}${data[2] ? '-draft' : 's'}/${data[0]}`}>{data[1]}</CustomLink>
            ) : (
              '-'
            ),
          sort: (a, b) => a[1].localeCompare(b[1]),
        };
        break;
      case 'nameWithStatus':
        modifiedConfig = {
          ...modifiedConfig,
          component: data =>
            data ? (
              <div style={{ display: 'flex' }}>
                <CustomLink href={`/admin/${config.field}${data.isDraft ? '-draft' : 's'}/${data.id}`}>
                  {data.name}
                </CustomLink>
                {data.status === enums.ClientStatus.TERMINATED && (
                  <Icon fontSize="small" style={{ color: 'red' }}>
                    close
                  </Icon>
                )}
                {data.status !== enums.ClientStatus.TERMINATED && data.terminationDate && (
                  <Icon fontSize="small" style={{ color: 'red', marginLeft: 2 }}>
                    schedule
                  </Icon>
                )}
              </div>
            ) : (
              '-'
            ),
          sort: (a, b) => a[1].localeCompare(b[1]),
        };
        break;
      case 'longText':
        modifiedConfig = {
          ...modifiedConfig,
          component: data => <div className={classes.longText}>{data}</div>,
        };
        break;
      default:
        break;
    }
    return modifiedConfig;
  };
  const exportCsv = (data, columns) => {
    const dataToDownload = getProcessedCsvData ? getProcessedCsvData() : columns;
    const csv = Papa.unparse(dataToDownload);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${csvFileName}.csv`);
  };

  return { createColumnsArray, exportCsv };
};
