import React, { lazy, Suspense } from 'react';
import CodeIcon from '@material-ui/icons/Code';
import enums from 'enums';
const resource = enums.Resources.DEVELOPERS;

const DevelopersView = lazy(() => import('views/DevelopersView/DevelopersView'));

export default {
  path: '/developers',
  name: 'developers',
  icon: CodeIcon,
  render: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <DevelopersView />
    </Suspense>
  ),
  layout: '/admin',
  randomKey: true,
  resource,
};
