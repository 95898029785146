import { blackColor, hexToRgb, primaryColor, whiteColor, dangerColor } from 'assets/jss/material-dashboard-pro-react';

const customSwitchStyle = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    paddingTop: 16,
    marginBottom: 2,
    boxSizing: 'content-box',
    width: '100%',
    justifyContent: 'space-between',
  },
  checked: {
    color: primaryColor[0] + '!important',
  },
  switchContainer: {
    marginLeft: 10,
    width: 45,
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: `${primaryColor[0]}`,
    },
    '& .MuiSwitch-colorSecondary.Mui-checked.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: `grey`,
    },
  },
  switchBase: {
    color: primaryColor[0] + '!important',
    '&:hover': {
      backgroundColor: 'rgba(' + hexToRgb(primaryColor[0]) + ', 0.14) !important',
    },
  },
  switchIcon: {
    boxShadow: '0 1px 3px 1px rgba(' + hexToRgb(blackColor) + ', 0.4)',
    color: whiteColor + '  !important',
    border: '1px solid rgba(' + hexToRgb(blackColor) + ', .54)',
  },
  switchIconDisabled: {
    color: '#D3D3D3' + '  !important',
  },
  switchBar: {
    width: '30px',
    height: '15px',
    backgroundColor: 'rgb(' + hexToRgb('#aaa') + ')',
    borderRadius: '15px',
    opacity: '0.7!important',
  },
  tooltip: {
    marginLeft: 15,
  },
  icon: {
    color: '#6f7f88',
  },
  labelContainer: {
    display: 'flex',
  },
  labelRootError: {
    color: dangerColor[0] + ' !important',
    fontWeight: 300,
    marginTop: -2,
  },
});

export default customSwitchStyle;
