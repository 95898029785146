import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button.js';
import CustomModal from 'components/CustomModal/CustomModal.js';
import styles from './filePreviewModalStyle';
const useStyles = makeStyles(styles);
export default function FilePreviewModal(props) {
  const { isOpen, onClose, file, toBeConfirmed, onConfirm, isLoading, fileUrl, fileName } = props;
  const classes = useStyles();
  const [objectUrl, setObjectUrl] = useState(null);
  useEffect(() => {
    if (!file) return;
    const objectUrl = URL.createObjectURL(file);
    setObjectUrl(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);
  return (
    <Transition mountOnEnter unmountOnExit in={isOpen} timeout={300}>
      <CustomModal
        open={isOpen}
        onClose={onClose}
        style={{
          width: 1000,
          height: 600,
        }}
      >
        <DocViewer
          documents={[{ uri: objectUrl || fileUrl, fileName: file?.name || fileName }]}
          pluginRenderers={DocViewerRenderers}
          prefetchMethod="GET"
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: true,
              disableThemeScrollbar: true,
            },
          }}
          theme={{
            primary: '#48BD45',
            secondary: '#ffffff',
            textPrimary: '#3c4858',
            disableThemeScrollbar: true,
          }}
          style={toBeConfirmed && { height: '90%', marginBottom: 20 }}
        />
        {toBeConfirmed && (
          <div className={classes.buttonContainer}>
            <Button onClick={onConfirm} color="primary" loadFromRight loading={isLoading} className={classes.button}>
              Confirm
            </Button>
          </div>
        )}
      </CustomModal>
    </Transition>
  );
}

FilePreviewModal.defaultProps = {
  isOpen: false,
};

FilePreviewModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  file: PropTypes.object,
  toBeConfirmed: PropTypes.bool,
  onConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
  fileUrl: PropTypes.string,
  fileName: PropTypes.string,
};
