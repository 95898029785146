import * as yup from 'yup';

export default [
  {
    column: 1,
    fields: [
      {
        name: 'name',
        type: 'text',
        label: 'Name',
        validation: yup.string().required('Required'),
      },
      {
        name: 'healthIkNumber',
        type: 'text',
        label: 'ikNumber',
        validation: yup.string().required('Required'),
      },
      {
        name: 'nursingIkNumber',
        type: 'text',
        label: 'nursingInsuranceNumber',
        validation: yup.string().required('Required'),
      },
      {
        name: 'buildingNumber',
        type: 'text',
        label: 'buildingNumber',
      },
      {
        name: 'street',
        type: 'text',
        label: 'street',
      },
      {
        name: 'zipCode',
        type: 'text',
        label: 'zipCode',
      },
      {
        name: 'city',
        type: 'text',
        label: 'city',
      },
    ],
  },
];
