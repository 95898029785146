const formFieldStyle = () => ({
  icon: {
    marginTop: 32,
  },
  info: {
    color: '#6f7f88',
  },
  danger: {
    color: '#df4759',
  },
  warning: {
    color: '#ffcc00',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
  },
  width85: {
    width: '85%',
  },
  width100: {
    width: '100%',
  },

  tooltip: {
    width: '10%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  extraText: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: -30,
    bottom: 18,
    fontWeight: 500,
    fontSize: 13,
  },
});

export default formFieldStyle;
