import validator from 'validator/index';

export default {
  required: { required: 'Password is required.' },
  username: {
    required: 'Username is required.',
    maxLength: {
      value: 50,
      message: 'Username cannot exceed 60 characters.',
    },
  },
  password: {
    minLength: {
      value: 8,
      message: 'Password should be at least 8 characters.',
    },
    maxLength: {
      value: 60,
      message: 'Password cannot exceed 60 characters.',
    },
  },
  email: {
    required: 'This field is required.',
    pattern: {
      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Email not valid',
    },
    maxLength: {
      value: 50,
      message: 'Email cannot exceed 50 characters.',
    },
  },
  phone: {
    required: 'This field is required.',
    validate: {
      phone: value => validator.isMobilePhone(`+20${value.substring(value.indexOf('1'))}`) || 'Phone number not valid',
    },
  },
};
