import {
  grayColor,
  roseColor,
  primaryColor,
  secondaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  blackColor,
  whiteColor,
  twitterColor,
  facebookColor,
  googleColor,
  linkedinColor,
  pinterestColor,
  youtubeColor,
  tumblrColor,
  behanceColor,
  dribbbleColor,
  redditColor,
  instagramColor,
  hexToRgb,
  yellowColor,
} from 'assets/jss/material-dashboard-pro-react';

const buttonStyle = {
  button: {
    minHeight: 'auto',
    minWidth: 'auto',
    backgroundColor: grayColor[0],
    color: whiteColor,
    border: 'none',
    borderRadius: '5px',
    position: 'relative',
    padding: '6px 20px',
    margin: '.3125rem 1px',
    fontSize: 14,
    fontWeight: '400',
    textTransform: 'capitalize',
    letterSpacing: '0',
    willChange: 'box-shadow, transform',
    transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    // "&:hover,&:focus": {
    //   color: whiteColor,
    //   backgroundColor: grayColor[0],
    // },
    '& .far,& .fal,& .material-icons': {
      position: 'relative',
      display: 'inline-block',
      top: '0',
      marginTop: '-1em',
      marginBottom: '-1em',
      fontSize: '1.1rem',
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    '& svg': {
      position: 'relative',
      display: 'inline-block',
      // top: -1,
      width: 20,
      height: 20,
      marginLeft: -7,
      verticalAlign: 'middle',
    },
    '&$justIcon': {
      '& .fab,& .far,& .fal,& .material-icons': {
        margin: 0,
        position: 'absolute',
        width: '100%',
        transform: 'none',
        left: '0px',
        top: '0px',
        height: '100%',
        lineHeight: '41px',
        fontSize: '20px',
      },
    },
    '& .MuiButton-label': {
      // position: "absolute",
    },
  },
  storedButton: {
    margin: '0px 5px',
  },
  fullWidth: {
    width: '100%',
  },
  primary: {
    backgroundColor: primaryColor[0],
    '&:hover': {
      backgroundColor: 'rgba(' + hexToRgb(primaryColor[0]) + ', 0.8)',
    },
    '&:foucs': {
      backgroundColor: primaryColor[0],
    },
  },
  primaryText: {
    color: `${primaryColor[0]} !important`,
  },
  secondary: {
    color: hexToRgb(whiteColor),
    backgroundColor: secondaryColor[0],
    '&:hover': {
      backgroundColor: 'rgba(' + hexToRgb(secondaryColor[0]) + ', 0.8)',
    },
    '&:foucs': {
      backgroundColor: secondaryColor[0],
    },
  },
  info: {
    backgroundColor: infoColor[0],
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(infoColor[0]) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(infoColor[0]) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(infoColor[0]) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: infoColor[0],
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(infoColor[0]) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(infoColor[0]) +
        ', 0.2)',
    },
  },
  success: {
    backgroundColor: successColor[0],
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(successColor[0]) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(successColor[0]) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(successColor[0]) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: successColor[0],
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(successColor[0]) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(successColor[0]) +
        ', 0.2)',
    },
  },
  successText: {
    color: `${successColor[0]} !important`,
  },
  warning: {
    backgroundColor: warningColor[0],
    '&:hover': {
      backgroundColor: 'rgba(' + hexToRgb(warningColor[0]) + ', 0.8)',
    },
    '&:foucs': {
      backgroundColor: warningColor[0],
    },
  },
  warningText: {
    color: `${warningColor[0]} !important`,
  },
  yellow: {
    backgroundColor: yellowColor[0],
    '&:hover': {
      backgroundColor: 'rgba(' + hexToRgb(yellowColor[0]) + ', 0.8)',
    },
    '&:foucs': {
      backgroundColor: yellowColor[0],
    },
  },
  yellowText: {
    color: `${yellowColor[0]} !important`,
  },
  gray: {
    backgroundColor: grayColor[0],
    '&:hover': {
      backgroundColor: 'rgba(' + hexToRgb(grayColor[0]) + ', 0.8)',
    },
    '&:foucs': {
      backgroundColor: grayColor[0],
    },
  },
  grayText: {
    color: `${grayColor[0]} !important`,
  },
  lightGray: {
    backgroundColor: grayColor[0],
    '&:hover': {
      backgroundColor: 'rgba(' + hexToRgb('#bbb') + ', 0.8)',
    },
    '&:foucs': {
      backgroundColor: grayColor[0],
    },
  },
  lightGrayText: {
    color: `#bbb !important`,
  },
  danger: {
    backgroundColor: dangerColor[0],
    '&:hover': {
      backgroundColor: 'rgba(' + hexToRgb(dangerColor[0]) + ', 0.8)',
    },
    '&:foucs': {
      backgroundColor: dangerColor[0],
    },
  },
  dangerText: {
    color: `${dangerColor[0]} !important`,
  },
  rose: {
    backgroundColor: roseColor[0],
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(roseColor[0]) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(roseColor[0]) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(roseColor[0]) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: roseColor[0],
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(roseColor[0]) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(roseColor[0]) +
        ', 0.2)',
    },
  },
  white: {
    border: '1px solid #ddd',
    '&,&:focus,&:hover': {
      backgroundColor: whiteColor,
      color: '#555',
    },
  },
  whiteText: {
    color: `${whiteColor} !important`,
  },
  twitter: {
    backgroundColor: twitterColor,
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(twitterColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(twitterColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(twitterColor) +
      ', 0.12)',
    '&:hover,&:focus,&:visited': {
      backgroundColor: twitterColor,
      color: whiteColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(twitterColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(twitterColor) +
        ', 0.2)',
    },
  },
  facebook: {
    backgroundColor: facebookColor,
    color: whiteColor,
    '&:hover,&:focus': {
      backgroundColor: facebookColor,
      color: whiteColor,
    },
  },
  google: {
    backgroundColor: googleColor,
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(googleColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(googleColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(googleColor) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: googleColor,
      color: whiteColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(googleColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(googleColor) +
        ', 0.2)',
    },
  },
  linkedin: {
    backgroundColor: linkedinColor,
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(linkedinColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(linkedinColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(linkedinColor) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: linkedinColor,
      color: whiteColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(linkedinColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(linkedinColor) +
        ', 0.2)',
    },
  },
  pinterest: {
    backgroundColor: pinterestColor,
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(pinterestColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(pinterestColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(pinterestColor) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: pinterestColor,
      color: whiteColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(pinterestColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(pinterestColor) +
        ', 0.2)',
    },
  },
  youtube: {
    backgroundColor: youtubeColor,
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(youtubeColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(youtubeColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(youtubeColor) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: youtubeColor,
      color: whiteColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(youtubeColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(youtubeColor) +
        ', 0.2)',
    },
  },
  tumblr: {
    backgroundColor: tumblrColor,
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(tumblrColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(tumblrColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(tumblrColor) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: tumblrColor,
      color: whiteColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(tumblrColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(tumblrColor) +
        ', 0.2)',
    },
  },
  github: {
    backgroundColor: grayColor[8],
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(grayColor[8]) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(grayColor[8]) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(grayColor[8]) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: grayColor[8],
      color: whiteColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(grayColor[8]) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(grayColor[8]) +
        ', 0.2)',
    },
  },
  behance: {
    backgroundColor: behanceColor,
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(behanceColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(behanceColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(behanceColor) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: behanceColor,
      color: whiteColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(behanceColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(behanceColor) +
        ', 0.2)',
    },
  },
  dribbble: {
    backgroundColor: dribbbleColor,
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(dribbbleColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(dribbbleColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(dribbbleColor) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: dribbbleColor,
      color: whiteColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(dribbbleColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(dribbbleColor) +
        ', 0.2)',
    },
  },
  reddit: {
    backgroundColor: redditColor,
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(redditColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(redditColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(redditColor) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: redditColor,
      color: whiteColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(redditColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(redditColor) +
        ', 0.2)',
    },
  },
  instagram: {
    backgroundColor: instagramColor,
    color: whiteColor,
    boxShadow:
      '0 2px 2px 0 rgba(' +
      hexToRgb(instagramColor) +
      ', 0.14), 0 3px 1px -2px rgba(' +
      hexToRgb(instagramColor) +
      ', 0.2), 0 1px 5px 0 rgba(' +
      hexToRgb(instagramColor) +
      ', 0.12)',
    '&:hover,&:focus': {
      backgroundColor: instagramColor,
      color: whiteColor,
      boxShadow:
        '0 14px 26px -12px rgba(' +
        hexToRgb(instagramColor) +
        ', 0.42), 0 4px 23px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 8px 10px -5px rgba(' +
        hexToRgb(instagramColor) +
        ', 0.2)',
    },
  },
  black: {
    backgroundColor: blackColor,
    color: whiteColor,
    '&:hover': {
      backgroundColor: 'rgba(' + hexToRgb(grayColor[1]) + ', 0.9)',
    },
  },
  blackText: {
    color: `${blackColor} !important`,
  },
  simple: {
    '&,&:focus,&:hover': {
      color: whiteColor,
      background: 'transparent',
      boxShadow: 'none',
    },
    '&$primary': {
      '&,&:focus,&:hover,&:visited': {
        color: primaryColor[0],
      },
    },
    '&$info': {
      '&,&:focus,&:hover,&:visited': {
        color: infoColor[0],
      },
    },
    '&$success': {
      '&,&:focus,&:hover,&:visited': {
        color: successColor[0],
      },
    },
    '&$warning': {
      '&,&:focus,&:hover,&:visited': {
        color: warningColor[0],
      },
    },
    '&$yellow': {
      '&,&:focus,&:hover,&:visited': {
        color: yellowColor[0],
      },
    },
    '&$gray': {
      '&,&:focus,&:hover,&:visited': {
        color: grayColor[0],
      },
    },
    '&$rose': {
      '&,&:focus,&:hover,&:visited': {
        color: roseColor[0],
      },
    },
    '&$danger': {
      '&,&:focus,&:hover,&:visited': {
        color: dangerColor[0],
      },
    },
    '&$twitter': {
      '&,&:focus,&:hover,&:visited': {
        color: twitterColor,
      },
    },
    '&$facebook': {
      '&,&:focus,&:hover,&:visited': {
        color: facebookColor,
      },
    },
    '&$google': {
      '&,&:focus,&:hover,&:visited': {
        color: googleColor,
      },
    },
    '&$linkedin': {
      '&,&:focus,&:hover,&:visited': {
        color: linkedinColor,
      },
    },
    '&$pinterest': {
      '&,&:focus,&:hover,&:visited': {
        color: pinterestColor,
      },
    },
    '&$youtube': {
      '&,&:focus,&:hover,&:visited': {
        color: youtubeColor,
      },
    },
    '&$tumblr': {
      '&,&:focus,&:hover,&:visited': {
        color: tumblrColor,
      },
    },
    '&$github': {
      '&,&:focus,&:hover,&:visited': {
        color: grayColor[8],
      },
    },
    '&$behance': {
      '&,&:focus,&:hover,&:visited': {
        color: behanceColor,
      },
    },
    '&$dribbble': {
      '&,&:focus,&:hover,&:visited': {
        color: dribbbleColor,
      },
    },
    '&$reddit': {
      '&,&:focus,&:hover,&:visited': {
        color: redditColor,
      },
    },
    '&$instagram': {
      '&,&:focus,&:hover,&:visited': {
        color: instagramColor,
      },
    },
    '&$black': {
      '&,&:focus,&:hover,&:visited': {
        color: blackColor,
      },
    },
  },
  transparent: {
    '&,&:focus,&:hover': {
      color: 'inherit',
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  round: {
    borderRadius: '100px',
  },
  disabled: {
    // opacity: "0.65",
    backgroundColor: grayColor[12],
    pointerEvents: 'none',
    boxShadow: 'none',
    '&,&:focus,&:hover': {
      color: 'inherit',
      backgroundColor: grayColor[12],
      boxShadow: 'none',
    },
  },
  clickDisabled: {
    // opacity: "0.65",
    pointerEvents: 'none',
    // "&,&:focus,&:hover": {
    //   color: "inherit",
    // },
  },
  lg: {
    '&$justIcon': {
      '& .fab,& .fas,& .far,& .fal,& svg,& .material-icons': {
        margin: 0,
        marginTop: '-4px',
      },
    },
    padding: '1.125rem 2.25rem',
    fontSize: '0.875rem',
    lineHeight: '1.333333',
  },
  sm: {
    '&$justIcon': {
      '& .fab,& .fas,& .far,& .fal,& svg,& .material-icons': {
        margin: 0,
        marginTop: '1px',
      },
    },
    padding: '0.40625rem 1.25rem',
    fontSize: '0.6875rem',
    lineHeight: '1.5',
  },
  block: {
    width: '100% !important',
  },
  link: {
    '&,&:hover,&:focus': {
      backgroundColor: 'transparent',
      color: grayColor[0],
      boxShadow: 'none',
    },
  },
  justIcon: {
    margin: 0,
    paddingLeft: '12px',
    paddingRight: '12px',
    fontSize: '20px',
    height: '41px',
    minWidth: '41px',
    width: '41px',
    '& .fab,& .fas,& .far,& .fal,& svg,& .material-icons': {
      margin: 0,
    },
    '&$lg': {
      height: '57px',
      minWidth: '57px',
      width: '57px',
      lineHeight: '56px',
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        fontSize: '32px',
        lineHeight: '56px',
      },
      '& svg': {
        // width: "32px",
        // height: "32px",
      },
    },
    '&$sm': {
      height: '30px',
      minWidth: '30px',
      width: '30px',
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        fontSize: '17px',
        lineHeight: '29px',
      },
      '& svg': {
        // width: "17px",
        // height: "17px",
      },
    },
  },
  fileButton: {
    // display: "inline-block"
  },
  loadingIcon: {
    '& svg': {
      margin: 0,
      fill: whiteColor,
      color: whiteColor,
    },
    marginBottom: 2,
    fill: whiteColor,
    color: primaryColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content !important',
    height: 'fit-content !important',
    position: 'absolute',
    transition: '0.3s all',
    opacity: 0,
  },
  loadingIconLeft: {
    left: -5,
  },
  loadingIconRight: {
    right: -5,
  },
  loadingIconVisible: {
    opacity: 1,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  childrenContainer: {
    transition: '0.3s all',
    width: 'fit-content',
    margin: 0,
  },
  moveChildrenRight: {
    marginLeft: 25,
    marginRight: -7,
  },
  moveChildrenLeft: {
    marginRight: 25,
  },
  loadingblack: {
    '& svg': {
      margin: 0,
      fill: blackColor,
      color: blackColor,
    },
  },
  icon: {
    marginRight: 4,
    transition: '0.3s all',
  },
  sendIcon: {
    marginLeft: '7px !important',
    marginRight: '-7px !important',
    marginBottom: '1px !important',
    marginTop: '-1px !important',
    width: '17px !important',
    height: '17px !important',
    transition: '0.3s all',
  },
  editIcon: {
    marginRight: 7,
    width: '18px !important',
  },
  adornmentVisible: {
    '& .adornment-transition': {
      opacity: 1,
    },
  },
  adornmentInVisible: {
    '& .adornment-transition': {
      opacity: 0,
      marginRight: -20,
    },
  },
  displayNone: { display: 'none' },
};

export default buttonStyle;
