import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from 'components/CustomButtons/Button';
import CustomLink from 'components/CustomLink/CustomLink';
import api from 'services/api';
import { useHttpClient } from 'shared/hooks/http-hook';
import translations from 'constants/translations';
import enums from 'enums/index';
import styles from './FailedLetters.styles';
const useStyles = makeStyles(styles);

export const useFailedLetters = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [sendRequest, isLoading] = useHttpClient();
  const [sendResolveRequest, isResolveLoading] = useHttpClient();
  const [letters, setLetters] = useState([]);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [selectedLetterName, setSelectedLetterName] = useState('');
  const [fileUrl, setFileUrl] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    fetchLetters(0);
  }, []);

  const fetchLetters = async tab => {
    tab = tab != null ? tab : currentTab;
    const resolved = tab == 1;
    try {
      const { letters } = await sendRequest(
        api.letterResource.getAll(
          undefined,
          undefined,
          undefined,
          { statusId: enums.ClientLetterStatuses.ERROR, resolved },
          undefined,
        ),
      );
      setLetters(letters);
    } catch (err) {
      console.log(err);
    }
  };

  const onTabChange = async (event, value) => {
    setCurrentTab(value);
    await fetchLetters(value);
  };

  const resolveLetter = async ({ letterId, resolved }) => {
    await sendResolveRequest(api.letterResource.patch({ letterId, resolved }));
    await fetchLetters();
  };

  const lettersData = useMemo(
    () =>
      letters?.map(letter => {
        const resolved = currentTab == 0;
        const buttonText = currentTab == 0 ? 'Move To Resolve' : 'Move Back';
        return [
          letter.letterId,
          letter.clientId,
          <CustomLink key={`${JSON.stringify(letter)}-client`} href={`/admin/clients/${letter.clientId}?tab=4`}>
            {letter.client?.firstName + ' ' + letter.client?.lastName}
          </CustomLink>,
          letter.admin?.name,
          letter.fileName,
          translations[i18n.language].letterTypesOBJ[
            letter.type === null || letter.type === undefined ? -1 : letter.type
          ],
          letter.statusDetails,
          letter.invoiceId,
          letter.invoiceDate,
          letter.acceptanceDate ? letter.acceptanceDate.split('T')[0] : '',
          letter.processedDate ? letter.processedDate.split('T')[0] : '',
          <div className={classes.actionsContainer} key={letter?.letterId}>
            <Tooltip
              title={!letter?.fileUrl ? t('cannotPreviewFileNotFound') : ''}
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <div>
                <Button
                  key={letter?.letterId}
                  color="white"
                  textColor="success"
                  className={classes.marginRight}
                  justIcon
                  disabled={!letter?.fileUrl}
                  onClick={() => {
                    setIsPreviewModalOpen(true);
                    setSelectedLetterName(letter?.fileName);
                    setFileUrl(letter?.fileUrl);
                  }}
                >
                  <VisibilityIcon color={!letter?.fileUrl ? 'disabled' : 'success'} />
                </Button>
              </div>
            </Tooltip>
            <Button
              key={`button-${JSON.stringify(letter)}`}
              onClick={() => resolveLetter({ letterId: letter.letterId, resolved })}
              color={currentTab == 0 ? 'primary' : 'danger'}
              loading={isResolveLoading}
            >
              {buttonText}
            </Button>
          </div>,
        ];
      }),
    [i18n.language, letters],
  );

  return {
    lettersData,
    isLoading,
    isPreviewModalOpen,
    selectedLetterName,
    fileUrl,
    setIsPreviewModalOpen,
    currentTab,
    onTabChange,
    resolveLetter,
  };
};
