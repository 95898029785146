import React, { lazy, Suspense } from 'react';
import PollIcon from '@material-ui/icons/Poll';
import enums from 'enums';

const resource = enums.Resources.RANKING;
const Ranking = lazy(() => import('views/Ranking/Ranking'));

export default {
  path: '/ranking',
  name: 'ranking',
  icon: PollIcon,
  render: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <Ranking />
    </Suspense>
  ),
  layout: '/admin',
  randomKey: true,
  resource,
};
