import { primaryColor, hexToRgb } from 'assets/jss/material-dashboard-pro-react';

const styles = () => ({
  link: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-line',
    color: primaryColor[0],
    transition: 'all 0.3s',
    fontWeight: 500,
    '&:hover': {
      color: 'rgba(' + hexToRgb(primaryColor[0]) + ',0.6) !important',
    },
    '&:focus,&:visited': {
      color: primaryColor[0],
      textDecoration: 'none',
    },
  },
  linkIcon: {
    fontSize: 13,
    marginLeft: 5,
  },

  searchContainer: { display: 'flex', alignItems: 'center' },
});

export default styles;
