export default () => ({
  dateText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'pre-line',
    maxWidth: 150,
  },
  longText: {
    maxWidth: 250,
    whiteSpace: 'normal',
  },
});
export const materialTableClasses = {
  tableStyle: {
    boxShadow: 'none',
  },

  cellStyle: {
    padding: '12px 8px',
    fontFamily: 'Roboto',
    fontSize: '1em',
    fontWeight: '300',
    width: 'auto',
  },
  headerStyle: {
    padding: '12px 8px',
    fontFamily: 'Roboto',
    fontSize: '1em',
    fontWeight: '300',
    whiteSpace: 'nowrap',
  },
  rowStyle: {
    whiteSpace: 'nowrap',
  },
};
