import React, { lazy, Suspense } from 'react';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import enums from 'enums';

const HelperOnboardingsPage = lazy(() =>
  import('views/HelperOnboardings/pages/HelperOnboardingsPage/HelperOnboardingsPage'),
);
const resource = enums.Resources.HELPER_ONBOARDINGS;

export default {
  path: '/helperOnboardings',
  name: 'Helper Onboarding',
  icon: AssignmentTurnedInIcon,
  render: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <HelperOnboardingsPage />
    </Suspense>
  ),
  layout: '/admin',
  randomKey: true,
  resource,
};
