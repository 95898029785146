import React, { lazy, Suspense } from 'react';
import ReceiptIcon from '@material-ui/icons/Receipt';

import enums from 'enums';

const InvoicesPage = lazy(() => import('views/Invoices/pages/InvoicesPage/InvoicesPage'));
const resource = enums.Resources.CLIENTS_INVOICES;

export default {
  path: '/invoices',
  name: 'Clients Invoices',
  icon: ReceiptIcon,
  render: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <InvoicesPage />
    </Suspense>
  ),
  layout: '/admin',
  randomKey: true,
  resource,
};
