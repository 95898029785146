import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button.js';
import { useCardList } from './CardList.hook';
import styles from './CardList.styles';
const useStyles = makeStyles(styles);

export default function CardList({
  children,
  list,
  setList,
  index,
  setIndex,
  noDataText = 'noClient',
  withNavigation = true,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ref, handlePrev, handleNext, handleKeyDown } = useCardList({
    list,
    setList,
    index,
    setIndex,
  });
  return list?.length > 0 ? (
    <div ref={ref} onKeyDown={handleKeyDown} tabIndex="0">
      <div className={classes.buttonsContainer}>
        <div className={classes.clientsNumber}>{`Showing client #${index + 1} out of ${list.length}`}</div>
        <Tooltip title={t('previousClient')} placement="top" classes={{ tooltip: classes.tooltip }}>
          <Button
            color="primary"
            justIcon
            round
            className={classes.button}
            onClick={handlePrev}
            disabled={index === 0 || !withNavigation}
          >
            <NavigateBeforeIcon className={classes.icon} />
          </Button>
        </Tooltip>
        <Tooltip title={t('nextClient')} placement="top" classes={{ tooltip: classes.tooltip }}>
          <Button
            color="primary"
            justIcon
            round
            className={classes.button}
            onClick={handleNext}
            disabled={index === list.length - 1 || !withNavigation}
          >
            <NavigateNextIcon className={classes.icon} />
          </Button>
        </Tooltip>
      </div>
      {children}
    </div>
  ) : (
    <div style={{ marginTop: 50 }}>{t(noDataText)}</div>
  );
}

CardList.propTypes = {
  list: PropTypes.array,
  setList: PropTypes.func,
  index: PropTypes.number,
  setIndex: PropTypes.func,
  children: PropTypes.any,
  noDataText: PropTypes.string,
  withNavigation: PropTypes.bool,
};
