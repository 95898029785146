import serviceCategoriesFormStructure from 'views/DropdownValues/Forms/serviceCategoriesFormStructure';
import api from 'services/api';
import { getDropdownValuesCrud } from '../dropdownValuesCrud';

export default getDropdownValuesCrud({
  name: 'serviceCategory',
  pluralName: 'serviceCategories',
  formStructure: serviceCategoriesFormStructure,
  dataResource: api.serviceCategoriesResource,
});
