const lettersStyle = () => ({
  root: {
    padding: '40px 20px',
    paddingBottom: 30,
  },
  uploadButton: {
    marginBottom: 25,
    height: 35,
  },
});

export default lettersStyle;
