import commonDE from './de/common';
import commonEN from './en/common';

export default {
  de: {
    ...commonDE,
  },
  en: {
    ...commonEN,
  },
};
