import React, { lazy, Suspense } from 'react';
import BarChart from '@material-ui/icons/BarChart';
import ClientsBudgetList from 'views/Statistics/pages/ClientsBudgetList/ClientsBudgetList';
import enums from 'enums';

const StatisticsPage = lazy(() => import('views/Statistics/pages/StatisticsPage/StatisticsPage'));
const UsersList = lazy(() => import('views/Statistics/pages/UsersList/UsersList'));

const resource = enums.Resources.STATISTICS;

const path = 'statistics';
export default {
  path: `/${path}`,
  name: 'Statistics',
  icon: BarChart,
  render: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <StatisticsPage />
    </Suspense>
  ),
  layout: '/admin',
  // randomKey: true,
  resource,
  children: true,
  views: [
    {
      path: `/${path}/helperList`,
      name: 'Helpers Details',
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          <UsersList path={path} isClient={false} />{' '}
        </Suspense>
      ),
      layout: '/admin',
      randomKey: true,
      resource,
    },
    {
      path: `/${path}/clients/budget`,
      name: 'Clients Details',
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          <ClientsBudgetList />
        </Suspense>
      ),
      layout: '/admin',
      randomKey: true,
      resource,
    },
    {
      path: `/${path}/clientList`,
      name: 'Clients Details',
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          {' '}
          <UsersList path={path} isClient={true} />
        </Suspense>
      ),
      layout: '/admin',
      randomKey: true,
      resource,
    },
  ],
};
