import { container, cardTitle, whiteColor, grayColor, dangerColor } from 'assets/jss/material-dashboard-pro-react.js';

const loginPageStyle = theme => ({
  container: {
    ...container,
    zIndex: '4',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px',
    },
    textAlign: 'center',
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center !important',
  },
  customButtonClass: {
    '&,&:focus,&:hover': {
      color: whiteColor,
    },
    marginLeft: '5px',
    marginRight: '5px',
  },
  inputAdornment: {
    marginRight: '18px',
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
  },
  cardHeader: {
    marginBottom: 20,
    height: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  socialLine: {
    padding: '0.9375rem 0',
  },
  helperText: {
    color: dangerColor[0] + ' !important',
    margin: '20px 0 0 0',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 400,
  },
});

export default loginPageStyle;
