import adminIcon from '@material-ui/icons/Lock';
import { getDropdownValuesCrud } from 'routes/dropdownValuesCrud';
import adminsFormStructure from 'views/Admins/adminsFormStructure';
import api from 'services/api';

import enums from 'enums';

const adminTypesObj = {
  [enums.AdminTypes.NORMAL]: 'Normal',
  [enums.AdminTypes.SUPER]: 'Super',
};
const tableHead = ['id', 'name', 'email', 'Admin Type'];

const rawDataConvertor = listData =>
  listData?.map(entry => {
    return [entry.id, entry.name, entry.email, adminTypesObj[entry.adminType]];
  });

export default getDropdownValuesCrud({
  resource: enums.Resources.ADMINS,
  name: 'admin',
  formStructure: adminsFormStructure,
  dataResource: api.adminsResource,
  customRawDataConvertor: rawDataConvertor,
  tableHead,
  withDelete: true,
  icon: adminIcon,
});
