import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import Loading from 'components/Loading/Loading';
import styles from './customSwitchStyle';

const useStyles = makeStyles(styles);

export default function CustomSwitch(props) {
  const classes = useStyles();

  const {
    value,
    onChange,
    error,
    helperText,
    labelText,
    rootStyle,
    disabled,
    tooltip,
    tooltipIconClasses,
    loading,
    name,
    ...rest
  } = props;

  const [valueState, setValueState] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setValueState(value);
  }, [value]);
  const tooltipContent = tooltip && (
    <div className={classes.tooltip}>
      <Tooltip title={tooltip} arrow>
        <InfoOutlined className={tooltipIconClasses} />
      </Tooltip>
    </div>
  );
  return (
    <div>
      <div style={rootStyle} className={classes.root}>
        <div className={classes.labelContainer}>
          <label htmlFor={`${name ? name : labelText.replace(/\s/g, '')}-switch`} className={classes.label}>
            {t(labelText)}
          </label>
          {tooltipContent}
        </div>
        <Loading loading={loading}>
          <div className={classes.switchContainer}>
            <Switch
              id={`${name ? name : labelText.replace(/\s/g, '')}-switch`}
              disabled={disabled}
              checked={valueState}
              onChange={onChange}
              classes={{
                switchBase: classes.switchBase,
                checked: classes.switchChecked,
                thumb: !disabled ? classes.switchIcon : classes.switchIconDisabled,
                track: classes.switchBar,
              }}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              {...rest}
            />
          </div>
        </Loading>
      </div>
      {helperText && error ? (
        <FormHelperText id={labelText + '-text'} className={classes.labelRootError}>
          {helperText}
        </FormHelperText>
      ) : null}
    </div>
  );
}

CustomSwitch.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  labelText: PropTypes.string,
  rootStyle: PropTypes.object,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipIconClasses: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string,
};
