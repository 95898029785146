const FailedLettersStyle = () => ({
  tooltip: {
    fontSize: 13,
    fontWeight: 400,
  },
  marginRight: {
    marginRight: '10px',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default FailedLettersStyle;
