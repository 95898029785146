import React, { lazy, Suspense } from 'react';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import enums from 'enums';

const ClientTasksPage = lazy(() => import('views/ClientTasks/pages/ClientTasksPage/ClientTasksPage'));

const resource = enums.Resources.HELPER_TASKS;

export default {
  path: '/clientTasks',
  name: 'Client Tasks',
  icon: AssignmentTurnedInIcon,
  render: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <ClientTasksPage />
    </Suspense>
  ),
  layout: '/admin',
  randomKey: true,
  resource,
};
