import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import dropFilesStyle from './dropFilesStyle';
import FilesList from './FilesList/FilesList';
const useStyles = makeStyles(dropFilesStyle);

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 15,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: 'white',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginBottom: 15,
  cursor: 'pointer',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function DropFiles({ maxFiles, setFiles, files, dropFileProps = {} }) {
  const classes = useStyles();

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        if (maxFiles) {
          setFiles(
            files ? [...[...acceptedFiles, ...files].slice(0, maxFiles)] : [...acceptedFiles.slice(0, maxFiles)],
          );
        } else {
          setFiles(files ? [...files, ...acceptedFiles] : [...acceptedFiles]);
        }
      }
    },
    [files, maxFiles, setFiles],
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    ...dropFileProps,
    ...(maxFiles && { maxFiles }),
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject],
  );

  let filesData = [];

  files.length > 0 &&
    files.forEach((file, index) => {
      filesData.push([
        <span key={index}>{file.name}</span>,
        // <span key={index}>{file.name.split(".").pop().toUpperCase()}</span>,
        <DeleteIcon key={JSON.stringify(file)} onClick={() => onFileRemoved(index)} className={classes.deleteIcon} />,
      ]);
    });

  const onFileRemoved = index => {
    let updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const acceptedTypes = useMemo(
    () =>
      Array.isArray(dropFileProps.accept)
        ? dropFileProps.accept.map((type, idx) => `${type}${idx !== dropFileProps.accept.length - 1 ? ', ' : ''}`)
        : dropFileProps.accept,
    [dropFileProps.accept],
  );
  return (
    <div className={classes.container}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <CloudUploadIcon className={classes.uploadIcon} />
        <p style={{ marginBottom: 0 }}>Drag & Drop files here, or click to select files</p>
        {dropFileProps?.accept && <p>{`(Only ${acceptedTypes} will be accepted)`}</p>}
      </div>
      {files.length > 0 ? <FilesList files={files} removeHandler={onFileRemoved} /> : null}
      {/* {files.length > 0 ? (
        <Table
          tableHead={["Name", " "]}
          tableData={filesData}
          tableHeaderColor="secondary"
          round
          pagination
        />
      ) : null} */}
    </div>
  );
}

DropFiles.propTypes = {
  maxFiles: PropTypes.number,
  setFiles: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  dropFileProps: PropTypes.object,
};
