import { primaryColor, hexToRgb } from 'assets/jss/material-dashboard-pro-react.js';

const breadcrumbsStyle = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  icon: {
    margin: '0px 10px',
    fontSize: 13,
  },
  link: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: primaryColor[0],
    transition: 'all 0.3s',
    fontWeight: 500,
    '&:hover': {
      color: 'rgba(' + hexToRgb(primaryColor[0]) + ',0.6) !important',
    },
    '&:focus,&:visited': {
      color: primaryColor[0],
      textDecoration: 'none',
    },
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    color: primaryColor[0],
    fontWeight: 500,
  },
});

export default breadcrumbsStyle;
