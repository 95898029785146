import * as yup from 'yup';
import enums from 'enums';
export default [
  {
    column: 1,
    fields: [
      {
        name: 'email',
        type: 'text',
        label: 'Email',
        validation: yup.string().required('Required').email('Please enter a valid email'),
      },
      {
        name: 'name',
        type: 'text',
        label: 'Name',
        validation: yup.string().required('Required'),
      },
      {
        name: 'password',
        type: 'text',
        label: 'Password',
        validation: yup.string().min(8, 'Must be 8 characters or more'),
      },
      {
        name: 'adminType',
        type: 'select',
        options: {
          data: [
            { name: 'Normal', value: enums.AdminTypes.NORMAL },
            { name: 'Super', value: enums.AdminTypes.SUPER },
          ],
        },
        label: 'Admin Type',
        validation: yup.string().required('Required'),
      },
      {
        name: 'slackId',
        type: 'text',
        label: 'Slack Id',
        validation: yup.string().trim().length(11, 'Must be 11 characters'),
      },
    ],
  },
];
