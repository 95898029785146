import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button.js';
import CustomModal from 'components/CustomModal/CustomModal.js';
import FormField from 'components/Forms/FormField/FormField';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import style from './UploadDocumentModal.styles';
const useStyles = makeStyles(style);

export default function UploadDocumentModal({ isOpen, onClose, form, onSubmit, isLoading }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { reset } = form;

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Transition mountOnEnter unmountOnExit in={isOpen} timeout={300}>
      <CustomModal open={isOpen} onClose={onClose}>
        <div>
          <p className={classes.title}>{t('Upload Files to send Letters')}</p>
        </div>
        <form className={classes.form} onSubmit={onSubmit} autoComplete="off">
          <GridContainer className={classes.visitContainer}>
            <GridItem xs={12} sm={12} md={12}>
              <div>
                <p className={classes.subTitle}>{t('Upload CSV file and  template Odt file')}</p>
                <p className={classes.note}>{t('uploadCsvNote')}</p>
              </div>
              <FormField
                type="dropzone"
                form={form}
                name="files"
                options={{ maxFiles: 2 }}
                rules={{ required: 'Required' }}
              />
            </GridItem>
          </GridContainer>

          <div className={classes.buttonContainer}>
            <Button color="primary" type="submit" loading={isLoading} loadFromRight className={classes.button}>
              Confirm
            </Button>
          </div>
        </form>
      </CustomModal>
    </Transition>
  );
}

UploadDocumentModal.defaultProps = {
  isOpen: false,
};

UploadDocumentModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  form: PropTypes.object,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  isUploadCsv: PropTypes.bool,
};
