const CardListStyle = () => ({
  tooltip: {
    fontSize: 13,
    fontWeight: 400,
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 20,
    alignItems: 'center',
  },
  clientsNumber: {
    marginRight: 20,
  },
  button: {
    marginRight: 10,
  },
  icon: {
    width: '30px !important',
    height: '30px !important',
  },
});

export default CardListStyle;
