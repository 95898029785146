import React, { useCallback, useState, useEffect, useRef } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './autocompleteInputStyle.js';

const useStyles = makeStyles(styles);

export default function AutocompleteInput(props) {
  const classes = useStyles();
  const {
    options,
    id,
    formControlProps,
    error,
    helperText,
    labelText,
    selectedValue,
    onChange,
    multiple,
    groupDisabled,
    ...rest
  } = props;

  const [value, setValue] = useState(multiple ? [] : null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (selectedValue) {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(formControlProps.className, classes.formControl);
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
  });

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underline]: true,
  });

  const inputClasses = classNames({
    [classes.input]: true,
  });

  const labelClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRoot]: true,
  });

  const onGroupClickHandler = useCallback(
    params => {
      const groupOptions = options?.filter(option => option.categoryName === params.group);
      // Get all the options in the selected group that are not already selected in the current
      // value to push them in the current value
      const difference = groupOptions?.filter(el => !value.includes(el));

      if (difference?.length > 0) {
        const tempValue = value;
        tempValue.push(...difference);
        onChange(undefined, tempValue);
        setValue(tempValue);
      } else {
        // If the user clicks on an already selected category, the following
        // filters out all the the options of the selected group from the current value
        const tempValue = value?.filter(el => !groupOptions.includes(el));
        onChange(undefined, tempValue);
        setValue(tempValue);
      }
      inputRef.current.blur();
    },
    [value, setValue, onChange, inputRef],
  );

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      <Autocomplete
        openOnFocus
        clearOnEscape
        clearOnBlur
        autoHighlight
        options={options}
        value={value}
        onChange={onChange}
        id={id}
        classes={{
          inputRoot: underlineClasses,
          input: inputClasses,
        }}
        renderGroup={
          groupDisabled
            ? undefined
            : params => {
                return (
                  <div>
                    <div
                      className={classes.groupName}
                      onClick={() => {
                        onGroupClickHandler(params);
                      }}
                    >
                      {params.group}
                    </div>
                    {params.children}
                  </div>
                );
              }
        }
        renderInput={params => {
          const newValue = params.id === 'street' ? params.inputProps?.value?.split(' (')[0] : params.inputProps?.value;
          return (
            <TextField
              {...params}
              inputRef={inputRef}
              inputProps={{
                ...params.inputProps,
                value: newValue,
                autocomplete: 'no',
                // form: {
                //   autocomplete: "off",
                // },
              }}
              label={labelText}
              InputLabelProps={{
                className: labelClasses,
                ...props.InputLabelProps,
              }}
              margin="normal"
            />
          );
        }}
        multiple={multiple}
        {...rest}
        blurOnSelect={false}
      />
      {helperText !== undefined ? (
        <FormHelperText id={id + '-text'} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

Autocomplete.propTypes = {
  options: PropTypes.array,
  labelText: PropTypes.node,
  id: PropTypes.string,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  selectedValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  groupDisabled: PropTypes.bool,
};
AutocompleteInput.propTypes = {
  options: PropTypes.array,
  labelText: PropTypes.node,
  id: PropTypes.string,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  selectedValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  groupDisabled: PropTypes.bool,
  InputLabelProps: PropTypes.object,
};
