import BuildIcon from '@material-ui/icons/Build';
import LoginPage from 'views/LoginPage/LoginPage';
import enums from 'enums';
import accommodationSituationRoutes from './entities/accommodationSituationRoutes';
import adminsRoutes from './entities/adminsRoutes';
import allergiesRoutes from './entities/allergiesRoutes';
import bugsRoutes from './entities/bugsRoutes';
import clientsDraftRoutes from './entities/clientsDraftRoutes';
import clientsRoutes from './entities/clientsRoutes';
import clientTasksRoutes from './entities/clientTasksRoutes';
import developersRoutes from './entities/developersRoutes';
import emailsRoutes from './entities/emailsRoutes';
import helperInvoicesRoutes from './entities/helperInvoicesRoutes';
import helperOnboardingsRoutes from './entities/helperOnboardingsRoutes';
import helpersDraftRoutes from './entities/helpersDraftRoutes';
import helpersRoutes from './entities/helpersRoutes';
import helperTasksRoutes from './entities/helperTasksRoutes';
import insuranceCompaniesRoutes from './entities/insuranceCompaniesRoutes';
import invoicesRoutes from './entities/invoicesRoutes';
import lettersRoutes from './entities/lettersRoutes';
import mapRoutes from './entities/mapRoutes';
import nursingAdvisorsRoutes from './entities/nursingAdvisorRoutes';
import petsRoutes from './entities/petsRoutes';
import rankingRoutes from './entities/rankingRoutes';

import recommendedByClientsRoutes from './entities/recommendedByClientsRoutes';
import recommendedByHelpersRoutes from './entities/recommendedByHelpersRoutes';
import restrictionsRoutes from './entities/restrictionsRoutes';
import serviceCategoriesRoutes from './entities/serviceCategoriesRoutes';
import servicesRoutes from './entities/servicesRoutes';
import statisticsRoutes from './entities/statisticsRoutes';
import supportsRoutes from './entities/supportsRoutes';

const routes = [
  {
    hidden: true,
    path: '/login',
    name: 'Login Page',
    rtlName: 'هعذاتسجيل الدخول',
    rtlMini: 'هعذا',
    component: LoginPage,
    layout: '/auth',
  },
  { ...clientsRoutes },
  { ...helpersRoutes },
  { ...helperTasksRoutes },
  { ...clientTasksRoutes },
  { ...invoicesRoutes },
  { ...helperInvoicesRoutes },
  { ...adminsRoutes },
  { ...clientsDraftRoutes },
  { ...helpersDraftRoutes },
  { ...helperOnboardingsRoutes },
  { ...mapRoutes },
  { ...statisticsRoutes },
  { ...rankingRoutes },
  { ...lettersRoutes },
  { ...emailsRoutes },
  {
    name: 'dropdownValues',
    icon: BuildIcon,
    layout: '/admin',
    collapse: true,
    state: 'dropdownValuesCollapse',
    resource: enums.Resources.DROPDOWN_VALUES,
    views: [
      { ...allergiesRoutes },
      { ...servicesRoutes },
      { ...serviceCategoriesRoutes },
      { ...restrictionsRoutes },
      { ...petsRoutes },
      { ...supportsRoutes },
      { ...insuranceCompaniesRoutes },
      { ...accommodationSituationRoutes },
      { ...recommendedByClientsRoutes },
      { ...recommendedByHelpersRoutes },
      { ...nursingAdvisorsRoutes },
    ],
  },
  { ...bugsRoutes },
  { ...developersRoutes },
];

export default routes;
