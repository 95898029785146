import { useState, useRef, useEffect } from 'react';

export const useCardList = ({ list, setList, index, setIndex }) => {
  const [isKeyboardEnabled, setIsKeyboardEnabled] = useState(true);

  const ref = useRef(null);

  useEffect(() => {
    if (index === list.length) {
      setIndex(prev => prev - 1);
    }

    if (index < 0) {
      setIndex(0);
    }
  }, [list]);

  const handleNext = () => {
    if (index + 1 < list.length) {
      setIndex(index + 1);
    } else {
      setIndex(list.length - 1);
    }
  };

  const handlePrev = () => {
    if (index - 1 >= 0) {
      setIndex(index - 1);
    } else {
      setIndex(0);
    }
  };

  const handleKeyDown = event => {
    if (isKeyboardEnabled) {
      if (event.keyCode == 37) {
        handlePrev();
      }
      if (event.keyCode == 39) {
        handleNext();
      }
    }
  };

  const setClient = clientIndex => data => {
    const tempClients = [...list];
    tempClients[clientIndex] = data;

    setList(tempClients);
  };

  return {
    index,
    ref,
    setClient,
    handlePrev,
    handleNext,
    setIsKeyboardEnabled,
    handleKeyDown,
  };
};
