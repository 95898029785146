import servicesFormStructure from 'views/DropdownValues/Forms/servicesFormStructure';
import api from 'services/api';
import { getDropdownValuesCrud } from '../dropdownValuesCrud';

// converts data fetched from backend to tableData
const rawDataConvertor = listData =>
  listData?.map(entry => {
    return [entry.id, entry.name, entry.categoryName];
  });

export default getDropdownValuesCrud({
  name: 'service',
  formStructure: servicesFormStructure,
  dataResource: api.servicesResource,
  customRawDataConvertor: rawDataConvertor,
  tableHead: ['id', 'name', 'serviceCategory'],
});
