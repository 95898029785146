import React, { lazy, Suspense } from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import api from 'services/api';
import enums from 'enums';

const HelperForm = lazy(() => import('views/Helpers/pages/HelperForm/HelperForm'));
const HelpersList = lazy(() => import('views/Helpers/pages/HelpersList/HelpersList'));
const HelperView = lazy(() => import('views/Helpers/pages/HelperView/HelperView'));

const path = 'helper-draft';

const resource = enums.Resources.HELPERS_DRAFT;

export default {
  path: `/${path}`,
  name: 'Helpers Draft',
  icon: AssignmentIcon,
  render: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <HelpersList isDraft path={path} />
    </Suspense>
  ),
  layout: '/admin',
  children: true,
  resource,
  views: [
    {
      path: `/${path}/create`,
      name: 'Create Helper Draft',
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          <HelperForm create isDraft path={path} />
        </Suspense>
      ),
      layout: '/admin',
      randomKey: true,
      resource,
    },
    {
      path: `/${path}/:id`,
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          <HelperView isDraft path={path} />
        </Suspense>
      ),
      layout: '/admin',
      randomKey: true,
      children: true,
      resource,
      getName: async id => {
        try {
          const data = (await api.helperDraftsResource.getOne(id)).data;
          return (data.firstName || '') + ' ' + (data.lastName || '');
        } catch {
          return 'View Draft';
        }
      },
      views: [
        {
          path: `/${path}/:id/edit`,
          name: 'Edit Helper Draft',
          render: () => (
            <Suspense fallback={<div>Loading...</div>}>
              <HelperForm edit isDraft path={path} />
            </Suspense>
          ),
          layout: '/admin',
          randomKey: true,
          resource,
        },
      ],
    },
  ],
};
