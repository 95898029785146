import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import Loading from 'components/Loading/Loading';
import { useCustomMaterialTable } from './CustomMaterialTable.hook';
import style, { materialTableClasses } from './CustomMaterialTable.styles';
const useStyles = makeStyles(style);

export default function CustomMaterialTable({
  style,
  options,
  columnsConfig,
  getProcessedCsvData,
  tableData,
  title,
  loading,
  csvFileName,
}) {
  const classes = useStyles();

  const { createColumnsArray, exportCsv } = useCustomMaterialTable({
    materialTableClasses,
    classes,
    getProcessedCsvData,
    csvFileName,
  });
  return (
    <Loading loading={loading}>
      <MaterialTable
        title={title}
        columns={createColumnsArray(columnsConfig)}
        data={tableData}
        options={{
          grouping: true,
          headerStyle: materialTableClasses.headerStyle,
          rowStyle: materialTableClasses.rowStyle,
          thirdSortClick: false,
          showTitle: false,
          search: false,
          toolbar: false,
          pageSize: 10,
          paging: true,
          exportCsv: exportCsv,
          ...options,
        }}
        style={{ ...materialTableClasses.tableStyle, ...style }}
      />
    </Loading>
  );
}

CustomMaterialTable.propTypes = {
  style: PropTypes.object,
  options: PropTypes.object,
  columnsConfig: PropTypes.array,
  tableData: PropTypes.array,
  title: PropTypes.string,
  loading: PropTypes.bool,
  getProcessedCsvData: PropTypes.func,
  csvFileName: PropTypes.string,
};
