import React, { useState, useEffect, useMemo } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// core components
import styles from './selectInputStyle';

export default function SelectInput(props) {
  const {
    id,
    labelText,
    labelProps,
    error,
    placeholder,
    selectStyle,
    data,
    onSelect,
    errorMessage,
    valid,
    selectedValue,
    color,
    disabled,
  } = props;

  const [value, setValue] = useState('');

  useEffect(() => {
    if (
      // selectedValue !== "" &&
      // selectedValue !== null &&
      selectedValue !== undefined
    ) {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  const useStyles = makeStyles(styles(color));

  const classes = useStyles();

  const selectFormControlClasses = classNames({
    [classes.selectFormControl]: true,
    [classes.underlineError]: error,
    [classes.selectedValueColor]: true,
  });

  const menuItems = data?.map((menuItem, index) => (
    <MenuItem
      key={`menu-item-${index}`}
      classes={{
        root: classes.selectMenuItem,
        selected: classes.selectMenuItemSelected,
      }}
      value={menuItem.value === null ? 'null' : typeof menuItem === 'object' ? menuItem.value : menuItem}
    >
      {typeof menuItem === 'object' ? menuItem.name : menuItem}
    </MenuItem>
  ));

  const handleSelect = event => {
    if (event.target.value === 'null') {
      event.target.value = null;
    }
    onSelect(event);
  };

  const hasNullChoice = useMemo(() => {
    return data?.some(menuItem => menuItem.value === null);
  }, [data]);

  return (
    <FormControl fullWidth className={selectFormControlClasses + ' ' + selectStyle} error={valid}>
      {labelText !== undefined ? (
        <InputLabel className={classes.selectLabel} htmlFor={`simple-select-${id}`} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        id={`simple-select-${id}-container`}
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={value === null && hasNullChoice ? 'null' : value}
        onChange={handleSelect}
        inputProps={{
          name: 'simpleSelect',
          id: `simple-select-${id}`,
          classes: {
            icon: classes.icon,
          },
        }}
        disabled={disabled}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}
          value={-1}
        >
          {placeholder}
        </MenuItem>
        {menuItems}
      </Select>
      <FormHelperText id="component-error-text" className={classes.labelRootError}>
        {errorMessage}
      </FormHelperText>
    </FormControl>
  );
}

SelectInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  placeholder: PropTypes.string,
  selectStyle: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  onSelect: PropTypes.func,
  errorMessage: PropTypes.string,
  valid: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger', 'rose']),
  id: PropTypes.string,
  disabled: PropTypes.bool,
};
