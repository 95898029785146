import React, { lazy, Suspense } from 'react';
import enums from 'enums';
const DataList = lazy(() => import('components/DataList/DataList'));
const FormPage = lazy(() => import('components/Forms/FormPage/FormPage'));

const rawDataConvertor = listData =>
  listData?.map(entry => {
    return [entry.id, entry.name];
  });

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const camelToKebabCase = str => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase());

export const getDropdownValuesCrud = ({
  name,
  formStructure,
  dataResource,
  tableHead = ['id', 'name'],
  resource = enums.Resources.DROPDOWN_VALUES,
  customRawDataConvertor,
  pluralName,
  withDelete,
  icon,
}) => {
  const layout = '/admin';
  const entityName = `${name}`;
  const path = camelToKebabCase(pluralName ? pluralName : `${name}s`);
  const listPageName = pluralName ? pluralName : `${name}s`;
  const createPageName = `create${capitalizeFirstLetter(name)}`;
  const editPageName = `edit${capitalizeFirstLetter(name)}`;
  return {
    path: `/${path}`,
    name: listPageName,
    icon,
    render: () => (
      <Suspense fallback={<div>Loading...</div>}>
        <DataList
          entityName={entityName}
          path={path}
          tableHead={tableHead}
          rawDataConvertor={customRawDataConvertor ? customRawDataConvertor : rawDataConvertor}
          actions={{ edit: true, delete: !!withDelete }}
          dataResource={dataResource}
        />
      </Suspense>
    ),
    layout: layout,
    randomKey: true,
    children: true,
    resource,
    views: [
      {
        path: `/${path}/create`,
        name: createPageName,
        render: () => (
          <Suspense fallback={<div>Loading...</div>}>
            <FormPage
              entityName={entityName}
              dataResource={dataResource}
              formStructure={formStructure}
              path={path}
              create
            />
          </Suspense>
        ),
        layout: layout,
        randomKey: true,
        resource,
      },
      {
        path: `/${path}/edit/:id`,
        name: editPageName,
        render: () => (
          <Suspense fallback={<div>Loading...</div>}>
            <FormPage
              entityName={entityName}
              dataResource={dataResource}
              formStructure={formStructure}
              path={path}
              edit
            />
          </Suspense>
        ),
        layout: layout,
        randomKey: true,
        resource,
      },
    ],
  };
};
