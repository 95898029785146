const usersListsListStyle = () => ({
  topContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 40,
    fontWeight: 600,
    fontSize: 20,
    paddingLeft: 10,
  },
  tagsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    marginLeft: 5,
  },
  tag: {
    color: 'white',
    borderRadius: 15,
    marginBottom: 5,
    fontWeight: 400,
    padding: '3px 8px',
    fontSize: 11,
    textAlign: 'center',
    textWrap: 'wrap',
  },
});

export default usersListsListStyle;
