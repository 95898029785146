import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getMaterialFileIcon } from 'file-extension-icon-js';
import PropTypes from 'prop-types';
import FilePreviewModal from 'components/FilePreviewModal/FilePreviewModal.js';
import { getSizeString } from 'utils';
import style from './fileCardStyle';
const useStyles = makeStyles(style);

export default function FileCard({ file, removeHandler }) {
  const supportedFormatsForPreview = [
    'bmp',
    'csv',
    'doc',
    'docx',
    'htm',
    'html',
    'jpg',
    'jpeg',
    'pdf',
    'png',
    'ppt',
    'pptx',
    'tiff',
    'gif',
    'txt',
    'xls',
    'xlsx',
  ];
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const openModal = () => {
    if (supportedFormatsForPreview.includes(file?.name.split('.').pop())) setIsPreviewOpen(true);
  };

  const previewButton = (
    <VisibilityIcon
      onClick={openModal}
      className={
        supportedFormatsForPreview.includes(file?.name.split('.').pop())
          ? classes.previewIcon
          : classes.previewIconNotSupported
      }
    />
  );
  return (
    <div className={classes.root}>
      <img className={classes.icon} src={`${getMaterialFileIcon(file.name)}`} alt="..." width="24" />
      <div className={classes.infoContainer}>
        <p className={classes.name}>{file?.name}</p>
        <p className={classes.size}>{getSizeString(file?.size)}</p>
      </div>
      {supportedFormatsForPreview.includes(file?.name.split('.').pop()) ? (
        previewButton
      ) : (
        <Tooltip title={t('formatNotSupported')} arrow>
          {previewButton}
        </Tooltip>
      )}

      <CancelIcon onClick={() => removeHandler()} className={classes.deleteIcon} />
      <FilePreviewModal file={file} isOpen={isPreviewOpen} onClose={() => setIsPreviewOpen(false)} />
    </div>
  );
}

FileCard.propTypes = {
  file: PropTypes.object,
  removeHandler: PropTypes.func,
};
