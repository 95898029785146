import React, { useEffect, useCallback } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import routes from 'routes';
import styles from 'assets/jss/material-dashboard-pro-react/layouts/authStyle.js';

const useStyles = makeStyles(styles);

export default function Pages() {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    document.body.style.overflow = 'unset';
    return () => {
      document.body.style.overflow = 'auto'; // Revert overflow on cleanup
    };
  }, []);

  const getBgImage = useCallback(() => {
    const { pathname } = location;
    const imageMap = {
      '/auth/register-page': 'register',
      '/auth/login': 'login',
      '/auth/pricing-page': 'bg-pricing',
      '/auth/lock-screen-page': 'lock',
      '/auth/error-page': 'error',
    };
    const imageName = imageMap[pathname] || 'login'; // Default image if path not found
    return require(`assets/img/${imageName}.jpeg`);
  }, [location.pathname]);

  const renderRoutes = useCallback(routesArray => {
    return routesArray.flatMap((route, key) => {
      const isAuthLayout = route.layout === '/auth';
      if (isAuthLayout) {
        if (!route.collapse) {
          return <Route path={route.layout + route.path} component={route.component} key={key} />;
        } else {
          return renderRoutes(route.views);
        }
      }
      return null;
    });
  }, []);

  return (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.fullPage} style={{ backgroundImage: `url(${getBgImage()})` }}>
          <Switch>
            {renderRoutes(routes)}
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
        </div>
      </div>
    </div>
  );
}
