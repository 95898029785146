import React from 'react';
import PropTypes from 'prop-types';
import FilePreviewModal from 'components/FilePreviewModal/FilePreviewModal';
import Loading from 'components/Loading/Loading';
import Table from 'components/Table/Table.js';
import { useLettersTable } from './LettersTable.hook';
export default function LettersTable({
  letters,
  isGetLoading,
  paginate,
  totalPosts,
  currentPage,
  isUpdateLetterLoading,
  handleLetterUpdate,
  selectedLetterId,
  withInvoiceData,
}) {
  const { tableData, isPreviewModalOpen, selectedLetterName, fileUrl, setIsPreviewModalOpen } = useLettersTable({
    letters,
    isUpdateLetterLoading,
    handleLetterUpdate,
    selectedLetterId,
    withInvoiceData,
  });

  return (
    <Loading loading={isGetLoading} defaultStyle={{ height: '100%' }}>
      <Table
        tableHeaderColor="primary"
        tableHead={[
          'Letter ID',
          'File Name',
          'File Type',
          'Status Details',
          withInvoiceData && 'Invoice Date',
          'Acceptance Date',
          'Processed Date',
          'Admin Name',
          'Actions',
        ]}
        tableData={tableData}
        backendPagination={!!paginate}
        pagination={!!paginate}
        currentPage={currentPage}
        paginate={paginate}
        totalPosts={totalPosts}
      />
      {fileUrl && (
        <FilePreviewModal
          fileUrl={fileUrl}
          fileName={selectedLetterName}
          isOpen={isPreviewModalOpen}
          onClose={() => setIsPreviewModalOpen(false)}
        />
      )}
    </Loading>
  );
}

LettersTable.propTypes = {
  letters: PropTypes.array,
  isGetLoading: PropTypes.bool,
  paginate: PropTypes.func,
  totalPosts: PropTypes.number,
  currentPage: PropTypes.number,
  isUpdateLetterLoading: PropTypes.bool,
  handleLetterUpdate: PropTypes.func,
  selectedLetterId: PropTypes.number,
  withInvoiceData: PropTypes.bool,
};
