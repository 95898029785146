import React, { useState, createRef, useEffect, useCallback, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import PerfectScrollbar from 'perfect-scrollbar';
import routes from 'routes';
import { useAuth } from 'shared/hooks/auth-hook';
import enums from 'enums';
import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

const Footer = lazy(() => import('components/Footer/Footer'));
const AdminNavbar = lazy(() => import('components/Navbars/AdminNavbar'));
const PrivateRoute = lazy(() => import('components/PrivateRoute/PrivateRoute'));
const Sidebar = lazy(() => import('components/Sidebar/Sidebar'));

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  const [appRoutes, setAppRoutes] = useState(false);
  const [image] = useState(null);
  const [color] = useState('primary');
  const [bgColor] = useState('white');
  const [logo] = useState(require('assets/img/big-logo.png'));
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  const { userData } = useAuth();
  const classes = useStyles();

  const mainPanelClasses =
    classes.mainPanel +
    ' ' +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
    });

  const mainPanel = createRef();

  useEffect(() => {
    if (userData !== false) {
      setIsLoading(false);
    }
  }, [userData]);

  useEffect(() => {
    if (mainPanel?.current && navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);

    return function cleanup() {
      if (mainPanel?.current && navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute = routes => {
    let activeRoute = 'JUHI';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          // if (routes[i].children) {
          //   let childRoute = getActiveRoute(routes[i].views);
          //   if (childRoute !== activeRoute) {
          //     return childRoute;
          //   }
          // }
          return routes[i];
        }
      }
    }
    return activeRoute;
  };

  const getRoutes = useCallback(routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      let childrenRoutes = [];
      if (prop.children) {
        childrenRoutes = getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return [
          ...childrenRoutes,
          <PrivateRoute
            path={prop.layout + prop.path}
            resource={prop.resource}
            render={prop.render}
            component={prop.component}
            key={prop.randomKey ? Math.random() : 'route - ' + key}
          />,
        ];
      } else {
        return null;
      }
    });
  }, []);

  useEffect(() => {
    setAppRoutes(getRoutes(routes));
  }, []);

  const filterRoutes = routes => {
    return routes.flatMap(route => {
      let childrenRoutes = [];
      if (route.collapse) {
        childrenRoutes = filterRoutes(route.views);
      }
      if (route.children) {
        childrenRoutes = filterRoutes(route.views);
      }
      if (userData?.adminType === enums.AdminTypes.SUPER) {
        return (route.children || route.collapse) && childrenRoutes.length
          ? [{ ...route, views: childrenRoutes }]
          : [route];
      } else if (
        ![enums.Resources.DROPDOWN_VALUES, enums.Resources.ADMINS, enums.Resources.DEVELOPERS].includes(route.resource)
      ) {
        return (route.children || route.collapse) && childrenRoutes.length
          ? [{ ...route, views: childrenRoutes }]
          : [route];
      } else {
        return [];
      }
    });
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  let logoCustomClass = classes.logoProduction;
  if (process.env.REACT_APP_NODE_ENVIRONMENT === 'development') logoCustomClass = classes.logoDevelope;
  if (process.env.REACT_APP_NODE_ENVIRONMENT === 'staging') logoCustomClass = classes.logoStage;

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress className="circular-progress" />
    </div>
  ) : (
    <Suspense fallback={<div>Loading...</div>}>
      <div className={classes.wrapper}>
        <Sidebar
          routes={filterRoutes(routes)}
          image={image}
          logo={logo}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          bgColor={bgColor}
          miniActive={miniActive}
          t={t}
          {...rest}
          customLogoClass={logoCustomClass}
        />
        <div id="main-panel" className={mainPanelClasses} ref={mainPanel}>
          <AdminNavbar
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
            activeRoute={getActiveRoute(routes)}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {appRoutes}
                <Redirect from="/admin" to="/admin/clients" />
              </Switch>
            </div>
          </div>
          <Footer fluid />
        </div>
      </div>
    </Suspense>
  );
}
