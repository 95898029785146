import React from 'react';
import { useTranslation } from 'react-i18next';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button.js';
import Loading from 'components/Loading/Loading';
import { useLetterPreview } from './LetterPreview.hook';
import styles from './LetterPreview.styles';
const useStyles = makeStyles(styles);
export default function LetterPreview({
  letterData,
  backendFileNameObj,
  onDeclineLetter,
  onSendLetter,
  isSendLetterLoading,
}) {
  const classes = useStyles();
  const { isLoading, fileUrl } = useLetterPreview({ letterData, backendFileNameObj });
  const { t } = useTranslation();
  return (
    <Card>
      <CardBody>
        <div className={classes.container}>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              onClick={() => onSendLetter(letterData)}
              className={classes.button}
              loading={isSendLetterLoading}
              disabled={isLoading}
            >
              {t('accept')}
            </Button>
            <Button color="danger" disabled={isLoading} onClick={onDeclineLetter} className={classes.button}>
              {t('decline')}
            </Button>
          </div>
          <div className={classes.viewerContainer}>
            <Loading loading={isLoading || isSendLetterLoading}>
              <DocViewer
                documents={[{ uri: fileUrl }]}
                pluginRenderers={DocViewerRenderers}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true,
                    disableThemeScrollbar: true,
                  },
                }}
                theme={{
                  primary: '#48BD45',
                  secondary: '#ffffff',
                  textPrimary: '#3c4858',
                  disableThemeScrollbar: true,
                }}
              />
            </Loading>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

LetterPreview.propTypes = {
  letterData: PropTypes.object,
  backendFileNameObj: PropTypes.object,
  onSendLetter: PropTypes.func,
  onDeclineLetter: PropTypes.func,
  isSendLetterLoading: PropTypes.bool,
};
