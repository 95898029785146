const LettersTableStyle = () => ({
  tooltip: {
    fontSize: 13,
    fontWeight: 400,
  },
  marginRight: {
    marginRight: '10px',
  },

  selectLoading: {
    marginTop: '22px !important',
    marginBottom: '22px !important',
    width: '24px !important',
    height: '24px !important',
  },
});

export default LettersTableStyle;
