import { getDropdownValuesCrud } from 'routes/dropdownValuesCrud';
import allergiesFormStructure from 'views/DropdownValues/Forms/allergiesFormStructure';
import api from 'services/api';

export default getDropdownValuesCrud({
  name: 'allergy',
  pluralName: 'allergies',
  formStructure: allergiesFormStructure,
  dataResource: api.allergiesResource,
});
