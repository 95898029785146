import axios from 'axios';

const BASE_URL = process.env.REACT_APP_SERVER_URL;
const REFRESH_TOKEN_UTL = '/auth/admin/refreshtoken';

const axiosInstance = axios.create({
  baseURL: `${BASE_URL}/`,
  withCredentials: true,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const refreshToken = async () => {
  try {
    const response = await axiosInstance.post(REFRESH_TOKEN_UTL);
    const accessToken = response.data.accessToken;
    setAuthToken(accessToken);
    processQueue(null, accessToken);
    return accessToken;
  } catch (error) {
    processQueue(error, null);
    return false;
  } finally {
    isRefreshing = false;
    createAxiosResponseInterceptor();
  }
};

const setAuthToken = token => {
  axiosInstance.defaults.headers.common.authorization = `Bearer ${token}`;
};

const createAxiosResponseInterceptor = () => {
  const interceptor = axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      if (!error.response || error.response.status !== 401) {
        if (error?.response?.status) {
          return Promise.reject(error);
        } else {
          return;
        }
      }

      let originalRequest = error.response.config;
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(accessToken => {
          originalRequest.headers.authorization = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        });
      }

      isRefreshing = true;
      axiosInstance.interceptors.response.eject(interceptor);
      try {
        const accessToken = await refreshToken();
        if (accessToken) {
          originalRequest.headers.authorization = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        } else if (!accessToken) {
          return Promise.resolve(false);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },
  );
};

export { setAuthToken, createAxiosResponseInterceptor, axiosInstance };
