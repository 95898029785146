import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from 'components/CustomButtons/Button';
import Loading from 'components/Loading/Loading';
import SelectInput from 'components/SelectInput/SelectInput';
import translations from 'constants/translations';
import enums from 'enums/index';
import styles from './LettersTable.style';
const useStyles = makeStyles(styles);

export const useLettersTable = ({
  letters,
  selectedLetterId,
  isUpdateLetterLoading,
  handleLetterUpdate,
  withInvoiceData,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [selectedLetterName, setSelectedLetterName] = useState('');
  const [fileUrl, setFileUrl] = useState(null);
  const letterTypeOptions = useMemo(
    () => [
      {
        name: translations[i18n.language].clientLetterTypesOBJ[enums.ClientLetterTypes.CONTRACT],
        value: enums.ClientLetterTypes.CONTRACT,
      },
      {
        name: translations[i18n.language].clientLetterTypesOBJ[enums.ClientLetterTypes.OTHER],
        value: enums.ClientLetterTypes.OTHER,
      },
    ],
    [i18n.language],
  );
  const tableData = useMemo(() => {
    return letters?.map(letter => [
      letter.letterId,
      letter.fileName,
      handleLetterUpdate ? (
        <Loading
          loading={isUpdateLetterLoading && letter.letterId === selectedLetterId}
          iconClass={classes.selectLoading}
          key={JSON.stringify(letter)}
        >
          <SelectInput
            selectStyle={classes.select}
            labelText=""
            placeholder={t('type')}
            data={letterTypeOptions}
            selectedValue={letter.type}
            onSelect={event => handleLetterUpdate(letter.clientId, letter.letterId, event)}
          />
        </Loading>
      ) : (
        translations[i18n.language].letterTypesOBJ[letter.type]
      ),
      letter.statusDetails,
      ...(withInvoiceData ? [letter.invoiceDate] : []),
      letter.acceptanceDate ? letter.acceptanceDate.split('T')[0] : '',
      letter.processedDate ? letter.processedDate.split('T')[0] : '',
      letter.admin?.name,
      <Tooltip
        key={letter?.letterId}
        title={!letter?.fileUrl ? t('cannotPreviewFileNotFound') : ''}
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <div>
          <Button
            key={letter?.letterId}
            color="white"
            textColor="success"
            className={classes.marginRight}
            justIcon
            disabled={!letter?.fileUrl}
            onClick={() => {
              setIsPreviewModalOpen(true);
              setSelectedLetterName(letter?.fileName);
              setFileUrl(letter?.fileUrl);
            }}
          >
            <VisibilityIcon color={!letter?.fileUrl ? 'disabled' : 'success'} />
          </Button>
        </div>
      </Tooltip>,
    ]);
  }, [letters, selectedLetterId, isUpdateLetterLoading, handleLetterUpdate]);

  return {
    tableData,
    isPreviewModalOpen,
    selectedLetterName,
    fileUrl,
    setIsPreviewModalOpen,
  };
};
