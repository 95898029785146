import React, { useState, useEffect, forwardRef } from 'react';

// @material-ui/core components
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import SendIcon from '@material-ui/icons/Send';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './buttonStyle.js';

const useStyles = makeStyles(styles);

const RegularButton = forwardRef((props, ref) => {
  const {
    color,
    textColor,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    fileButton,
    className,
    loading,
    loadingColor,
    loadFromRight,
    adornment,
    create,
    edit,
    download,
    deleteButton,
    send,
    success,
    info,
    cancelAndDelete,
    tooltip,
    ...rest
  } = props;

  const [loadingState, setLoadingState] = useState(loading);

  const { t } = useTranslation();
  useEffect(() => {
    if (!loading && loadingState) {
      // setTimeout(() => {
      setLoadingState(loading);
      // }, 1000);
    } else {
      setLoadingState(loading);
    }
  }, [loading]);

  const classes = useStyles();
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes[textColor + 'Text']]: textColor,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.clickDisabled]: loadingState,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [classes.fileButton]: fileButton,
    [classes.adornmentVisible]: !loadingState,
    [classes.adornmentInVisible]: loadingState,
    [classes.storedButton]: create || edit || deleteButton,
    [className]: className,
  });
  const childrenContainerClasses = classNames({
    [classes.childrenContainer]: true,
    [classes.moveChildrenLeft]: loadingState && loadFromRight,
    [classes.moveChildrenRight]: loadingState && !loadFromRight,
  });
  const loadingIconClasses = classNames({
    [classes.loadingIcon]: true,
    [classes.loadingIconVisible]: loadingState,
    [classes[`loading${loadingColor}`]]: loadingColor,
    [classes.loadingIconLeft]: !loadFromRight,
    [classes.loadingIconRight]: loadFromRight,
  });
  const tooltipClasses = classNames({
    [classes.displayNone]: !tooltip,
  });

  return (
    <Tooltip title={tooltip} placement="top" classes={{ tooltip: tooltipClasses }}>
      <Button {...rest} ref={ref} className={btnClasses}>
        {adornment ? (
          children
        ) : (
          <div className={classes.container} style={{ position: 'relative' }}>
            {loadFromRight ? null : (
              <Transition mountOnEnter unmountOnExit in={loadingState} timeout={30000}>
                <CircularProgress className={loadingIconClasses} />
              </Transition>
            )}
            <div className={childrenContainerClasses}>
              {create ? (
                <>
                  <AddIcon className={'adornment-transition ' + classes.icon} style={justIcon ? { margin: 0 } : {}} />
                  {justIcon ? '' : props.children || t('create')}
                </>
              ) : info ? (
                <>
                  <InfoIcon className={'adornment-transition ' + classes.icon} style={justIcon ? { margin: 0 } : {}} />
                  {justIcon ? '' : props.children || t('info')}
                </>
              ) : edit ? (
                <>
                  <EditIcon
                    className={'adornment-transition ' + classes.icon + ' ' + classes.editIcon}
                    style={justIcon ? { margin: 0 } : {}}
                  />
                  {justIcon ? '' : props.children || t('edit')}
                </>
              ) : download ? (
                <>
                  <i
                    className={'fas fa-download adornment-transition ' + classes.icon}
                    style={justIcon ? { margin: 0 } : {}}
                  />
                  {justIcon ? '' : props.children || t('download')}
                </>
              ) : deleteButton ? (
                <>
                  <DeleteIcon
                    className={'adornment-transition ' + classes.icon}
                    style={justIcon ? { margin: 0 } : {}}
                  />
                  {justIcon ? '' : props.children || t('delete')}
                </>
              ) : cancelAndDelete ? (
                <>
                  <DeleteIcon
                    className={'adornment-transition ' + classes.icon}
                    style={justIcon ? { margin: 0 } : {}}
                  />
                  {justIcon ? '' : props.children || t('cancel') + ' & ' + t('delete')}
                </>
              ) : success ? (
                <>
                  {children}
                  <CheckCircleIcon className={'adornment-transition ' + classes.sendIcon} />
                </>
              ) : send ? (
                <>
                  {children}
                  <SendIcon className={'adornment-transition ' + classes.sendIcon} />
                </>
              ) : (
                children
              )}
            </div>
            {loadFromRight ? (
              <Transition mountOnEnter unmountOnExit in={loadingState} timeout={30000}>
                <CircularProgress className={loadingIconClasses} />
              </Transition>
            ) : null}
          </div>
        )}
      </Button>
    </Tooltip>
  );
});

RegularButton.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'white',
    'twitter',
    'facebook',
    'google',
    'linkedin',
    'pinterest',
    'youtube',
    'tumblr',
    'github',
    'behance',
    'dribbble',
    'reddit',
    'instagram',
    'transparent',
    'disabled',
    'black',
    'yellow',
    'gray',
    'lightGray',
  ]),
  size: PropTypes.oneOf(['sm', 'lg']),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  fileButton: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  create: PropTypes.bool,
  deleteButton: PropTypes.bool,
  send: PropTypes.bool,
  textColor: PropTypes.string,
  loading: PropTypes.bool,
  loadingColor: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'danger', 'rose']),
  loadFromRight: PropTypes.bool,
  adornment: PropTypes.bool,
  edit: PropTypes.bool,
  download: PropTypes.bool,
  success: PropTypes.bool,
  info: PropTypes.bool,
  cancelAndDelete: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default RegularButton;
