import React, { lazy, Suspense } from 'react';
import ReceiptIcon from '@material-ui/icons/Receipt';
import enums from 'enums';

const HelperInvoicesPage = lazy(() => import('views/HelperInvoices/pages/HelperInvoicesPage/HelperInvoicesPage'));
const resource = enums.Resources.HELPERS_INVOICES;

export default {
  path: '/helperInvoices',
  name: 'Helper Invoices',
  icon: ReceiptIcon,
  render: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <HelperInvoicesPage />
    </Suspense>
  ),
  layout: '/admin',
  randomKey: true,
  resource,
};
