import React, { lazy, Suspense } from 'react';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import api from 'services/api';
import enums from 'enums';

const ClientForm = lazy(() => import('views/Clients/pages/ClientForm/ClientForm'));
const ClientsList = lazy(() => import('views/Clients/pages/ClientsList/ClientsList'));
const ClientView = lazy(() => import('views/Clients/pages/ClientView/ClientView'));

const path = 'client-draft';

const resource = enums.Resources.CLIENTS_DRAFT;

export default {
  path: `/${path}`,
  name: 'Clients Draft',
  icon: AssignmentIndIcon,
  render: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <ClientsList isDraft path={path} />{' '}
    </Suspense>
  ),
  layout: '/admin',
  children: true,
  resource,
  views: [
    {
      path: `/${path}/create`,
      name: 'Create Client Draft',
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          <ClientForm isDraft create path={path} />
        </Suspense>
      ),
      layout: '/admin',
      randomKey: true,
      resource,
    },
    {
      path: `/${path}/:id`,
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          <ClientView isDraft path={path} />
        </Suspense>
      ),
      layout: '/admin',
      randomKey: true,
      children: true,
      resource,
      getName: async id => {
        try {
          const data = (await api.clientDraftsResource.getOne(id)).data;
          return (data.firstName || '') + ' ' + (data.lastName || '');
        } catch {
          return 'View Draft';
        }
      },
      views: [
        {
          path: `/${path}/:id/edit`,
          name: 'Edit Client Draft',
          render: () => (
            <Suspense fallback={<div>Loading...</div>}>
              <ClientForm isDraft edit path={path} />
            </Suspense>
          ),
          layout: '/admin',
          randomKey: true,
          resource,
        },
      ],
    },
  ],
};
