import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Router, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';

import { Slide, ToastContainer } from 'react-toastify';
import DateFnsUtils from '@date-io/date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Tracker from '@openreplay/tracker';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import api from 'services/api';
import versionCheck from 'services/version-check';
import { AuthContext } from 'shared/context/auth-context';
import { useAuth } from 'shared/hooks/auth-hook';
import { setLocaleCookie } from 'utils';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import 'date-fns';

const history = createBrowserHistory();

const App = () => {
  const { userData, login, logout, updateUserData } = useAuth();
  const [trackerStarted, setTrackerStarted] = useState(false);
  const [sentryStarted, setSentryStarted] = useState(false);

  const { i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const tracker = useMemo(() => {
    return new Tracker({
      projectKey: process.env.REACT_APP_SESSION_RECORDING_PROJECT_KEY,
      __DISABLE_SECURE_MODE: true,
      ingestPoint: process.env.REACT_APP_SESSION_RECORDING_SERVER_URL,
      obscureTextNumbers: false,
      obscureTextEmails: false,
      obscureInputEmails: false,
      obscureInputDates: false,
      defaultInputMode: 0,
      network: {
        capturePayload: true,
        failuresOnly: false,
        axiosInstances: [api.axiosInstance, api.komootInstance],
      },
    });
  }, []);

  useEffect(() => {
    // this code will only run once, on mount
    versionCheck.startVersionCheck('/version.json');
  }, []); // empty array means no dependencies, so this effect only runs once

  useEffect(() => {
    (async () => {
      if (userData !== false) {
        setIsLoading(false);
      }
      if (
        userData?.id &&
        !sentryStarted &&
        !trackerStarted &&
        process.env.REACT_APP_NODE_ENVIRONMENT === 'production'
      ) {
        // Initializing Sentry
        console.log('Sentry Init');
        const sentrySessionId = Sentry.getCurrentHub().getScope()?.getSession()?.sid;
        const userTag = `${userData.email}-${sentrySessionId || new Date().getTime()}`;
        const sentrySessionUrl = `${process.env.REACT_APP_SENTRY_BASE_URL}${userTag.replace(/@/g, '%40')}`;
        Sentry.setTag('userFilter', userTag);
        Sentry.setUser({
          id: userData.id,
          email: userData.email,
          username: userData.name,
        });
        setSentryStarted(true);
        // Initializing Open Replay
        await tracker.start({
          userID: userData?.email,
          metadata: {
            Name: userData?.name,
            ID: userData?.id,
            ENV: process.env.REACT_APP_NODE_ENVIRONMENT,
          },
        });
        setTrackerStarted(true);
        updateUserData(userData, {
          sentrySessionUrl,
          openReplaySessionUrl: tracker.getSessionURL(),
        });
      }
    })();
  }, [userData, tracker]);

  useEffect(() => {
    const [detectedLanguage, detectedRegion] = i18n.language?.split('-');
    if (detectedRegion) i18n.changeLanguage(detectedLanguage);
    setLocaleCookie(detectedLanguage);
  }, [i18n]);

  const routes = useMemo(() => {
    if (userData?.id) {
      return [
        <Route path="/admin" component={AdminLayout} key="admin-route" />,
        <Redirect from="/" to="/admin/clients" key="redirect" />,
      ];
    } else {
      return [<Route path="/auth" component={AuthLayout} key="auth-route" />, <Redirect to="/auth" key="redirect" />];
    }
  }, [userData?.id]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!userData,
        userData: userData,
        login: login,
        logout: logout,
      }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router history={history}>
          <main>
            <ScrollToTop />
            <ToastContainer transition={Slide} />
            {isLoading ? (
              <div className="loading-container">
                <CircularProgress className="circular-progress" />
              </div>
            ) : (
              <div className="wrapper">
                <Switch>{routes}</Switch>
              </div>
            )}
          </main>
        </Router>
      </MuiPickersUtilsProvider>
    </AuthContext.Provider>
  );
};

export default App;
