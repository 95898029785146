import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import api from 'services/api';
import { useHttpClient } from 'shared/hooks/http-hook';
import enums from 'enums/index';
export const useLetters = () => {
  const [sendUploadRequest, isUploadLoading, sendUploadRequestError] = useHttpClient();
  const [sendSendLetterRequest, isSendLetterLoading, sendSendLetterRequestError] = useHttpClient();
  const [letters, setLetters] = useState([]);
  const [sendGetRequest, isGetLoading, sendGetRequestError] = useHttpClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(1);

  const [isUploadDocumentModalOpen, setIsUploadDocumentModalOpen] = useState(false);
  const [csvLetters, setCsvLetters] = useState([]);

  const [index, setIndex] = useState(0);
  const [backendFileNameObj, setBackendFileNameObj] = useState({});

  const form = useForm({
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues } = form;
  useEffect(() => {
    if (sendGetRequestError?.message) {
      toast.error('letter fetch error');
    }
  }, [sendGetRequestError]);

  useEffect(() => {
    fetchLetters(currentPage);
  }, [window.location.search]);

  const fetchLetters = async (page = currentPage) => {
    try {
      const { letters, count } = await sendGetRequest(
        api.letterResource.getAll(page, 10, undefined, { type: enums.LetterTypes.MANUAL }, undefined),
      );
      setLetters(letters);
      setTotalPosts(count);
      setCurrentPage(parseInt(page));
    } catch (err) {
      console.log(err);
    }
  };

  const paginate = useCallback(async pageNumber => {
    await fetchLetters(pageNumber);
  }, []);

  useEffect(() => {
    const unloadCallback = event => {
      if (csvLetters.length > 0) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [csvLetters]);

  useEffect(() => {
    if (sendUploadRequestError?.message) {
      toast.error(sendUploadRequestError?.message);
    }
    if (sendSendLetterRequestError?.message) {
      toast.error(sendSendLetterRequestError?.message);
    }
  }, [sendUploadRequestError, sendSendLetterRequestError]);

  const submitHandler = values => async () => {
    if (values.files.length != 2) {
      toast.error('Please upload 2 files');
      return;
    }
    const csvFile = values.files.find(el => el.type === 'text/csv');
    if (!csvFile) {
      toast.error('Please upload CSV file');
      return;
    }
    const odtFile = values.files.find(el => el.type === 'application/vnd.oasis.opendocument.text');
    if (!odtFile) {
      toast.error('Please upload ODT file');
      return;
    }

    Papa.parse(csvFile, {
      delimiter: '',
      header: true,
      complete: result => {
        setCsvLetters(result.data);
        uploadLetterDocument({ odtFile });
      },
    });

    setIsUploadDocumentModalOpen(false);
  };

  const uploadLetterDocument = async ({ odtFile }) => {
    const response = await sendUploadRequest(api.letterResource.uploadLetterOdt({ file: odtFile }));
    setBackendFileNameObj(response);
  };

  const onSubmit = e => {
    e.preventDefault();
    const values = getValues();
    handleSubmit(submitHandler(values))();
  };

  const onDeclineLetter = async () => {
    csvLetters[index].declined = true;
    incrementIndex();
  };

  const onSendLetter = async letterData => {
    try {
      await sendSendLetterRequest(api.letterResource.sendLetter({ letterData, ...backendFileNameObj }));
      toast.success('Letter sent successfully');

      csvLetters[index].declined = false;
      incrementIndex();
    } catch (err) {
      toast.error('Error Occurs');
    }
  };

  const incrementIndex = () => {
    if (index === csvLetters.length - 1) {
      onListFinished();
    } else setIndex(prevState => prevState + 1);
  };

  const onListFinished = async () => {
    downloadUnsentLetters();
    setCsvLetters([]);
    setIndex(0);
    setBackendFileNameObj({});
    fetchLetters();
  };

  const downloadUnsentLetters = () => {
    const unsentLetters = csvLetters.filter(letter => letter.declined === undefined || letter.declined);
    if (unsentLetters.length > 0) {
      const csv = Papa.unparse(unsentLetters);
      const csvBlob = new Blob([csv], {
        type: 'text/csv',
      });
      saveAs(csvBlob, `Unsent Letters.csv`);
    }
  };

  return {
    onSubmit,
    onDeclineLetter,
    onSendLetter,
    isUploadLoading,
    isUploadDocumentModalOpen,
    setIsUploadDocumentModalOpen,
    form,
    csvLetters,
    setCsvLetters,
    index,
    setIndex,
    backendFileNameObj,
    isSendLetterLoading,
    isGetLoading,
    paginate,
    totalPosts,
    currentPage,
    letters,
  };
};
