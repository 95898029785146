import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './customLinkStyles';
const useStyles = makeStyles(styles);

export default function CustomLink({ href, children }) {
  const classes = useStyles();

  return (
    <a target="_blank" rel="noopener noreferrer" className={classes.link} href={href}>
      {children}
    </a>
  );
}

CustomLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
};
