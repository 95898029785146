import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Pagination from 'components/Pagination/Pagination';
import FileCard from './FileCard/FileCard';
import style from './filesListStyle';
const useStyles = makeStyles(style);

export default function FilesList({ files, removeHandler }) {
  const [currentPage, setCurrentPage] = useState(1);

  const classes = useStyles();

  let postsPerPage = 10;

  let matchingFiles = files;
  let currentPageContent = files;

  const indexOfLastFile = currentPage * postsPerPage;
  const indexOfFirstFile = indexOfLastFile - postsPerPage;
  currentPageContent = matchingFiles.slice(indexOfFirstFile, indexOfLastFile);
  let currentPageContentElements = [];
  if (currentPageContent.length > 0) {
    currentPageContent.forEach((file, index) => {
      currentPageContentElements.push(
        <FileCard
          key={Math.random()}
          file={file}
          removeHandler={() => {
            if (files.length - 1 <= postsPerPage * (currentPage - 1)) {
              setCurrentPage(currentPage - 1);
            }
            removeHandler(index);
          }}
        />,
      );
    });
  }
  return (
    <div className={classes.root}>
      {files?.length > 0 ? (
        <>
          <div className={classes.filesContainer}>{currentPageContentElements}</div>
          {postsPerPage >= files?.length ? null : (
            <div className={classes.paginationContainer}>
              <Pagination
                color="primary"
                postsPerPage={postsPerPage}
                totalPosts={files?.length}
                paginate={page => {
                  setCurrentPage(page);
                }}
                currentPage={currentPage}
              />
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}

FilesList.propTypes = { files: PropTypes.array, removeHandler: PropTypes.func };
