import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

// @material-ui/core components
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';

// core components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import CardHeader from 'components/Card/CardHeader.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import api from 'services/api';
import { AuthContext } from 'shared/context/auth-context';
import { useHttpClient } from 'shared/hooks/http-hook';
import validators from 'shared/util/validators';
import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js';

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = useState('cardHidden');
  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation('');
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();

  const { register, handleSubmit, errors, getValues } = useForm();

  const [sendRequest, isLoading, error, clearError] = useHttpClient();

  const auth = useContext(AuthContext);

  const handleAuthentication = async () => {
    clearError();
    try {
      let authData = {
        email: getValues('email'),
        password: getValues('password'),
      };

      const response = await sendRequest(api.login(authData));

      auth.login(response);
    } catch (err) {
      toast.error('An error has occurred');
    }
  };

  return (
    <div className={classes.container}>
      <h2>Welcome to JUHI Dashboard</h2>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleSubmit(handleAuthentication)}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="black">
                <h4 className={classes.cardTitle}>Admin</h4>
              </CardHeader>

              {error && !error.param && (
                <FormHelperText className={classes.helperText} filled>
                  {error.message}
                </FormHelperText>
              )}

              <CardBody>
                <CustomInput
                  labelText="email"
                  id="email"
                  error={!!errors.email?.message}
                  helperText={errors.email?.message}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircleIcon className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    inputRef: register(validators.email),
                    name: 'email',
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  error={!!errors.password?.message}
                  helperText={errors.password?.message}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: 'password',
                    autoComplete: 'off',
                    inputRef: register({
                      ...validators.required,
                      ...validators.password,
                    }),
                    name: 'password',
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  type="submit"
                  color="black"
                  // simple
                  size="lg"
                  block
                  loading={isLoading}
                  loadingColor="white"
                >
                  Log in
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
