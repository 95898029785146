const letterPreviewStyle = theme => ({
  container: {
    display: 'flex',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    width: '50%',
  },
  button: {
    marginLeft: 20,
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      margin: 'auto',
    },
  },
  viewerContainer: {
    width: '50%',
  },
});

export default letterPreviewStyle;
