import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import api from 'services/api';
import enums from 'enums';

const HelperForm = lazy(() => import('views/Helpers/pages/HelperForm/HelperForm'));
const HelpersList = lazy(() => import('views/Helpers/pages/HelpersList/HelpersList'));
const HelperView = lazy(() => import('views/Helpers/pages/HelperView/HelperView'));

const helperIcon = ({ className }) => <i style={{ marginLeft: 1 }} className={'fas fa-hand-holding ' + className} />;

helperIcon.propTypes = { className: PropTypes.string };

const path = 'helpers';

const resource = enums.Resources.HELPERS;

export default {
  path: `/${path}`,
  name: 'Helpers',
  icon: helperIcon,
  render: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <HelpersList path={path} />
    </Suspense>
  ),
  layout: '/admin',
  children: true,
  resource,
  views: [
    {
      path: `/${path}/create`,
      name: 'Create Helper',
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          <HelperForm create path={path} />
        </Suspense>
      ),
      layout: '/admin',
      randomKey: true,
      resource,
    },
    {
      path: `/${path}/:id`,
      render: () => (
        <Suspense fallback={<div>Loading...</div>}>
          <HelperView path={path} />
        </Suspense>
      ),
      layout: '/admin',
      randomKey: true,
      children: true,
      resource,
      getName: async id => {
        try {
          const data = (await api.helpersResource.getOne(id)).data;
          return data.firstName + ' ' + data.lastName;
        } catch {
          return 'View Helper';
        }
      },
      views: [
        {
          path: `/${path}/:id/edit`,
          name: 'Edit Helper',
          render: () => (
            <Suspense fallback={<div>Loading...</div>}>
              <HelperForm edit path={path} />
            </Suspense>
          ),
          layout: '/admin',
          randomKey: true,
          resource,
        },
      ],
    },
  ],
};
